<template>
  <div :class="className" class="px-4 py-3 rounded-sm relative" role="alert">
    <strong class="font-bold" v-if="title">{{ title }}</strong>
    <span class="block sm:inline" v-if="message">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    type: {
      type: String,
      default: 'danger',
      validator: function(value) {
        return ['primary', 'success', 'warning', 'danger'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    className() {
      switch (this.type) {
        case 'success':
          return 'bg-success text-white';
        case 'warning':
          return 'bg-warning text-white';
        case 'danger':
          return 'bg-error text-white';
        default:
          return 'bg-primary text-primary-invert';
      }
    },
  },
};
</script>
