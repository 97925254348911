<template>
  <div class="flex flex-1 flex-col items-center justify-center">
    <img src="@/assets/svg/empty.svg" class="w-1/4" alt="" />
    <h4 class="text-xl mt-6">You don’t have any {{ name }} yet.</h4>
  </div>
</template>

<script>
import env from '@/config/env';

export default {
  props: {
    name: String,
    route: String,
  },
  computed: {
    appName() {
      return env.name;
    },
  },
  components: {},
};
</script>
