<template>
  <span>{{ displayTime }}</span>
</template>

<script>
import moment from '@/config/moment';

export default {
  props: {
    format: {
      type: String,
      default: 'YYYY-MM-DD hh:mm:ss A',
    },
    timestamp: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayTime() {
      return moment(this.timestamp).format(this.format);
    },
  },
};
</script>
