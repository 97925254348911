<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages">
    </Topbar>
    <div class="flex flex-col p-6 flex-1">
      <div class="border-b pb-2">
<!--        <div :key="index" v-for="(filterData, index) in filters" class="flex">-->
<!--          <div-->
<!--              class="w-1/3 sm:w-full cursor-pointer px-4 py-2"-->
<!--              :key="filter.name"-->
<!--              v-for="filter in filterData"-->
<!--          >-->
<!--          <div class="flex items-center">-->
<!--            <input id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">-->
<!--            <label for="checked-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ filter.display_name }}</label>-->
<!--          </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="flex" :key="index" v-for="(filterData, index) in filters">
          <div
              class="w-1/3 sm:w-full cursor-pointer px-4 py-2"
              :key="filter.name"
              v-for="filter in filterData"
          >
            <label :for="filter.name">{{ filter.display_name }}</label>

            <component
                v-if="filter.name=== 'start_date' || filter.name === 'end_date' || filter.name === 'completed_start_date'|| filter.name === 'completed_end_date'"
                :id="filter.name"
                v-bind:is="filter.component"
                :value="filterValues[filter.field]"
                data-te-toggle="tooltip"
                :title="filter.name=== 'start_date' || filter.name === 'end_date' ? 'Survey form open date' : 'Survey form submitted date'"
                :placeholder="
                `Please Select ${filter.placeholder ? filter.placeholder : filter.display_name}`
              "
                :key="
                filter.value_depend_field
                  ? filterValues[filter.value_depend_field]
                  : `${filter.name}_field`
              "
                :options="getFilterOptions(filter)"
                @selected="value => handleSelectFilterValue(filter, value)"
                v-bind="filter"
            >
            </component>
            <component
                v-else
                :id="filter.name"
                v-bind:is="filter.component"
                :value="filterValues[filter.field]"
                :placeholder="
                `Please Select ${filter.placeholder ? filter.placeholder : filter.display_name}`
              "
                :key="
                filter.value_depend_field
                  ? filterValues[filter.value_depend_field]
                  : `${filter.name}_field`
              "
                :options="getFilterOptions(filter)"
                @selected="value => handleSelectFilterValue(filter, value)"
                v-bind="filter"
            >
            </component>
          </div>
        </div>
      </div>
      <ResourceTable
          ref="resourceTable"
          :disableActions="false"
          name="SurveyArchiveResultTable"
          :columns="columns"
          :query="SurveyResultArchiveQuery"
          :queryKey="listSurveyResultArchivesQueryKey"
          :variables="SurveyResultArchiveVariables"
          :filterVariables="FilterValues"
          :exporting="exporting"
          :handleResetFilter="handleResetFilter"
          :handleExportCreate="handleExportCreate"
      >
        <template v-slot:default="slotProps">
          <div class="flex">
            <button
                type="button"
                @click="handleView(slotProps.item)"
                class="mx-2 px-2 py-2 text-left bg-gray-600 text-white"
            >
              View
            </button>
            <button
                v-if="slotProps.item.mode === 'submitted' && userGroup?.includes('Approver')"
                type="button"
                :disabled="submitting === slotProps.item.id"
                @click="handleApprove(slotProps.item)"
                class="mx-2 px-2 py-2 text-left bg-green-800 text-white"
            >
              <template v-if="submitting === slotProps.item.id">
                Approving
              </template>
              <template v-if="!submitting || submitting !== slotProps.item.id">
                Approve
              </template>
            </button>
            <button
                type="button"
                v-if="
              slotProps.item.mode !== 'draft' &&
                userGroup?.some(r => ['Approver', 'Admin', 'Super'].indexOf(r) >= 0)
            "
                :disabled="isLoading[slotProps.item.export_id]"
                @click="onDownload(slotProps.item)"
                style="background-color:#f700ff;"
                class="mx-2 px-2 py-2 text-left bg-purple-800 text-white"
            >
              {{ isLoading[slotProps.item.export_id] ? 'Downloading...' : 'Download' }}
            </button>
            <button
                v-if="slotProps.item.mode === 'submitted' && userGroup?.includes('Approver')"
                type="button"
                :disabled="submitting === slotProps.item.id"
                @click="handleDraft(slotProps.item)"
                class="mx-2 px-2 py-2 text-left bg-green-800 text-white"
            >
              <template v-if="submitting === slotProps.item.id">
                Converting
              </template>
              <template v-if="!submitting || submitting !== slotProps.item.id">
                Draft
              </template>
            </button>
          </div>
        </template>
      </ResourceTable>
    </div>
  </div>

</template>

<script>

import {mapGetters} from 'vuex';
import dayjs from "dayjs";
import ResourceTable from '@/components/NewResourceTable'
// import ResourceTable from '@/components/ResourceTable'
import {getSurveyResult, listSurveyResultArchives} from "@/graphql/queries";
import {filters} from './filters';
import chunk from "lodash-es/chunk";
import find from "lodash-es/find";
import moment from "moment-timezone";
import DatePicker from '@/components/DatePicker';
import SelectDropdown from '../../components/SelectDropdown';
import SearchDropdown from './components/SearchDropdown';
import RangeComponent from '../../components/RangeComponent';
import {API} from "aws-amplify";
import {graphqlOperation} from "@aws-amplify/api";
import {useToast} from "vue-toastification";

export default {
  components: {
    ResourceTable,
    SelectDropdown,
    DatePicker,
    RangeComponent,
    SearchDropdown
  },
  data() {
    return {
      exporting: false,
      submitting: null,
      filterValues: {},
      userGroup: null,
      survey: null,
      isLoading: [],
    }
  },
  computed: {
    ...mapGetters({
      group: 'app/getGroup',
      groups: 'app/groups',
    }),
    filters() {
      if (window.innerWidth <= 700) {
        return chunk(filters, 1);
      }
      return chunk(filters, 3);
    },
    columns() {
      return [
        {
          name: 'district',
          text: 'District',
          render: item => item?.district,
        },
        {
          name: 'divisional_secretariat',
          text: 'Divisional Secretariat',
          render: item => item?.divisional_secretariat,
        },
        {
          name: 'gn_division',
          text: 'GN Division',
          render: item => item?.gn_division,
        },
        {
          name: 'head_of_household',
          text: 'Head Of Household',
          render: item => item?.head_of_household,
        },
        {
          name: 'mode',
          text: 'Status',
          render: item => item?.mode,
        },
        {
          name: 'completedAt',
          text: 'Completed',
          render: item =>
              (item?.mode === 'processed') || (item?.mode === 'submitted') ? dayjs(item?.completedAt).format('YYYY-MM-DD') : '',
        },
        {
          name: 'createdAt',
          text: 'Created',
          render: item => dayjs(item?.createdAt).format('YYYY-MM-DD'),
        },
        {
          name: 'updatedAt',
          text: 'Updated',
          render: item =>
              item?.mode === 'draft' ? dayjs(item?.updatedAt).format('YYYY-MM-DD') : '',
        }
      ];
    },
    SurveyResultArchiveQuery() {
      return listSurveyResultArchives;
    },
    listSurveyResultArchivesQueryKey() {
      return 'listSurveyResultArchives';
    },
    FilterValues() {
      return this.filterValues
    },
    SurveyResultArchiveVariables() {
      const variables = {};
      variables.survey_id = this.id;
      variables['filter'] ={
        'createdAt':{'between':[moment().startOf('month').toDate(), moment().endOf('month').toDate()]}
      }
      return variables;
    },
    id() {
      return this.$route.params.id;
    },
    pages() {
      return [
        {
          name: 'Surveys',
          to: {name: 'surveys'},
          current: false,
        },
        {
          name: this.survey ? this.survey.survey_name : 'Surveys View',
          to: {name: 'surveys.results', params: {id: this.id}},
          current: false,
        },
      ];
    },
  },
  async created() {
    this.userGroup = await this.group;
    this.filterValues = {
      start_date: moment()
          .startOf('month')
          .toDate(),
      end_date: moment()
          .endOf('month')
          .toDate(),
    };
    var last_filter_variables = JSON.parse(localStorage.getItem('last_filter_variables') || '{}');
    if('filter' in last_filter_variables) {
      this.filters.map(filterData => {
        filterData.map(filter => {
          if(filter.name in last_filter_variables.filter){
            if(filter.type === 'autocomplete'){
              this.filterValues[filter.name] = last_filter_variables.filter[filter.name].eq;
            }
          }
          if('updatedAt' in last_filter_variables.filter || 'completedAt' in last_filter_variables.filter){
            this.filterValues['start_date'] = ('updatedAt' in last_filter_variables.filter) ? moment(last_filter_variables.filter.updatedAt.between[0]).toDate() : moment(last_filter_variables.filter.completedAt.between[0]).toDate();
            this.filterValues['end_date'] = ('updatedAt' in last_filter_variables.filter) ? moment(last_filter_variables.filter.updatedAt.between[1]).toDate() : moment(last_filter_variables.filter.completedAt.between[1]).toDate();
          }
        })
      })
    }
  },
  methods: {
    handleView(item) {
      this.$router.push({
        name: 'surveys.view',
        params: {id: this.id, result: item.id},
      });
    },
    async onDownload(item) {
      this.isLoading[item.survey_result_id] = true;
      const toast = useToast();
      await API.get('resultExporterExcel', `/export/cb03f59a-7a41-4244-baf1-3dec4b3cca05.csv`)
          .then(response => {
            if (response.success) {
              toast.success('Survey Download Successfully.', {
                timeout: 5000,
              });
              const a = document.createElement('a');
              a.href = response.url;
              a.download = name;
              a.click();
              a.remove();
            }
            this.isLoading[item.survey_result_id] = false;
          })
          .catch(() => {
            this.isLoading[item.survey_result_id] = false;
          });
    },
    async handleApprove(item) {
      this.submitting = item.id;
      const data = await API.graphql(
          graphqlOperation(getSurveyResult, {
            id: item.survey_result_id,
          }),
      );
      const surveyResult = data.data.getSurveyResult;
      const customInput = {
        id: item.survey_result_id,
        mode: 'completed',
        _version: surveyResult._version,
      };
      const updateSurveyResult = `mutation updateSurveyResult($input: UpdateSurveyResultInput!){
        updateSurveyResult(input: $input) {
            id
            mode
            survey_result
            survey_detail
            _version
            _lastChangedAt
        }
    }`;
      await API.graphql(graphqlOperation(updateSurveyResult, {input: customInput}));
      this.submitting = null;
    },
    async handleDraft(item) {
      this.submitting = item.id;
      const data = await API.graphql(
          graphqlOperation(getSurveyResult, {
            id: item.survey_result_id,
          }),
      );
      const surveyResult = data.data.getSurveyResult;
      const customInput = {
        id: item.survey_result_id,
        mode: 'draft',
        _version: surveyResult._version,
      };
      const updateSurveyResult = `mutation updateSurveyResult($input: UpdateSurveyResultInput!){
        updateSurveyResult(input: $input) {
            id
            mode
            survey_result
            survey_detail
            survey {
                id
            }
            _version
            _lastChangedAt
        }
    }`;
      await API.graphql(graphqlOperation(updateSurveyResult, {input: customInput}));
      this.submitting = null;
    },
    handleSelectFilterValue(filter, value) {
      this.filterValues[filter.field] = value;
    },
    setFilterValue(group, data, key, filterKey = 'value') {
      const item = data.filter(item => item[filterKey] === group)[0];
      if (item) {
        this.filterValues[key] = item.name;
      }
      return item;
    },
    handleGetBaseFilters() {
      if (!this.userGroup) {
        return null;
      }
      if (this.userGroup?.includes('Admin') || this.userGroup?.includes('Super')) {
        return null;
      }
      this.userGroup.map(group => {
        if (group.includes('district:')) {
          this.setFilterValue(group, this.groups.district, 'district');
        }
        if (group.includes('ds:')) {
          const divisional = this.setFilterValue(group, this.groups.ds, 'divisional_secretariat');
          if (divisional) {
            this.setFilterValue(divisional.district, this.groups.district, 'district', 'name');
          }
        }

        if (group.includes('gn:')) {
          const gn = this.setFilterValue(group, this.groups.gn, 'gn_division');
          if (gn) {
            const divisional = this.setFilterValue(
                gn.ds,
                this.groups.ds,
                'divisional_secretariat',
                'name',
            );
            if (divisional) {
              this.setFilterValue(divisional.district, this.groups.district, 'district', 'name');
            }
          }
        }
      });
    },
    getFilterOptions(filter) {
      this.handleGetBaseFilters();
      const mainData = find(filter.values, {key: 'data'});
      if (mainData && mainData.values) {
        return mainData.values;
      }
      if (filter.value_depend_field) {
        const dependField = this.filterValues[filter.value_depend_field];
        if (dependField) {
          const data = find(filter.values, {key: dependField});
          if (data && data.values) {
            return data.values;
          }
        } else {
          const values = filter.values;
          const deduced = values.map(value => value.values);
          const deducedIter = [];
          deduced.forEach(item => {
            item.map(value => {
              deducedIter.push(value);
            });
          });
          return deducedIter;
        }
      }
      return [];
    },
    handleResetFilter() {
      this.filterValues = {
        start_date: moment()
            .startOf('month')
            .toDate(),
        end_date: moment()
            .endOf('month')
            .toDate(),
      };
    },
    async handleExportCreate() {
      this.exporting = true;
    }
  },
};
</script>

<style src="vue-multiselect/dist/dist/vue-multiselect.css"></style>
