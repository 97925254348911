<template>
  <!--  lg:flex md:flex sm:flex -->
  <div class="flex flex-col flex-1">
    <Alert type="danger" :message="error" v-if="error"/>
    <NoItems :name="name" :route="createRoute" v-if="isEmpty && !forceTable"/>
    <template v-else-if="!error">
      <div class="mt-3 bg-surface-card-active flex justify-end rounded-sm">
        <button
            type="button"
            :disabled="isLoading"
            class="mx-2 px-2 py-2 text-left bg-red-400 text-white"
            :class="{'disabled:opacity-70 cursor-not-allowed': isLoading}"
            @click="filterOnClick"
        >
          Filter
        </button>
        <button
            :disabled="isLoading"
            @click="handleReset"
            style="background-color:#f700ff;"
            class="bg-purple-700 text-white px-2 py-2 mx-2"
            :class="{'disabled:opacity-70 cursor-not-allowed': isLoading}"
        >
          Reset Filters
        </button>
        <button
            @click="surveyExportExcel('filterable')"
            :disabled="isLoading"
            class="bg-green-800 text-white px-2 py-2 mx-2"
            :class="{'disabled:opacity-70 cursor-not-allowed': isLoading}"
            name="survey-result-archive.xls"
        >
          {{ isExporting ? 'Exporting...' : 'Create Export' }}
        </button>
        <button
            @click="surveyExportExcel('all')"
            :disabled="isLoading"
            class="bg-green-800 text-white px-2 py-2 mx-2"
            :class="{'disabled:opacity-70 cursor-not-allowed': isLoading}"
            name="survey-result-archive.xls"
        >
          {{ isExportingAll ? 'Exporting...' : 'Create Export All' }}
        </button>
        <!--            <export-excel-->
        <!--                :data="items"-->
        <!--                :fields = "json_fields"-->
        <!--                :disabled="isLoading"-->
        <!--                class="bg-green-800 text-white px-2 py-2 mx-2"-->
        <!--                :class="{'disabled:opacity-70 cursor-not-allowed': isLoading}"-->
        <!--                worksheet = "survey-result-archive-worksheet"-->
        <!--                name    = "survey-result-archive.xls"-->
        <!--            >-->
        <!--              Create Export-->
        <!--            </export-excel>-->
      </div>
      <Search @change="onSearch" v-if="searchable && !dateFilter"/>
      <ResourceTable :items="this.paginatedItems" :columns="columns" :is-loading="items.length <= 0 && isLoading"
                     @view="onView">
        <template v-slot:default="slotProps" v-if="actions.length && !disableActions">
          <slot :item="slotProps.item"></slot>
        </template>
      </ResourceTable>
      <div class="mt-3 bg-surface-card-active flex justify-between rounded-sm">
        <button
            type="button"
            class="mx-4 px-2 py-2 text-left bg-gray-600 text-white"
            :class="{'disabled:opacity-70 cursor-not-allowed': isLoading}"
            :disabled="this.currentPage <= 1 || isLoading"
            @click="this.currentPage--"
        >
          Prev Page
        </button>
        Per Page {{ this.perPage }} :
        Page {{ this.currentPage }} of {{ this.totalPages }} :
        Total {{ this.totalItems }}
        <button
            type="button"
            class="mx-4 px-2 py-2 text-left bg-red-400 text-white"
            :class="{'disabled:opacity-70 cursor-not-allowed': isLoading}"
            :disabled="this.currentPage >= this.totalPages || isLoading"
            @click="this.currentPage++"
        >
          Next Page
        </button>
      </div>
    </template>
    <SurveyExportWarningModel v-if="showSurveyExportWarningModel" @close-model="showSurveyExportWarningModel = false"/>
  </div>
</template>

<script>
import Alert from '@/components/Alert';
import actionItems from './action-items';
import ResourceTable from './ResourceTable';
import {graphqlOperation} from "@aws-amplify/api";
import {API} from "aws-amplify";
import Search from './Search';
import NoItems from './NoItems';
import {Amplify} from "@aws-amplify/core";
import moment from "moment";
import SurveyExportWarningModel from "@/views/Surveys/components/SurveyExportWarningModel.vue";
import {useToast} from "vue-toastification";
import {v4 as uuidv4} from "uuid";
import {createSurveyExport} from "@/graphql/mutations";
import {onUpdateSurveyExport} from "@/graphql/subscriptions";
import {listSurveyExports} from "@/graphql/queries";


// import {mapGetters} from "vuex";
// import moment from "moment-timezone";

export default {
  components: {
    NoItems,
    Alert,
    ResourceTable,
    SurveyExportWarningModel,
    Search,
  },
  props: {
    id: String,
    page: Number,
    api: String,
    url: String,
    filter: Function,
    model: Function,
    query: String,
    queryKey: String,
    subscriptionGql: String,
    variables: Object,
    name: String,
    createRoute: String,
    columns: Array,
    disableActions: Boolean,
    forceTable: Boolean,
    dateFilter: Boolean,
    actions: {
      type: Array,
      default: () => actionItems,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    extraParams: Object,
    filterVariables: Object,
    exporting: Boolean,
    handleResetFilter: Function,
    // handleExportCreate: Function
  },
  data() {
    return {
      items: [],
      filterKey: [],
      searchItems: [],
      meta: {current_page: this.page, last_page: this.page + 1, infinite: true, per_page: 10,},
      error: null,
      searchQuery: '',
      observer: null,
      nextToken: null,
      nextTokens: [],
      exportId: '',
      isEmpty: false,
      isLoaded: false,
      isLoading: false,
      isExporting: false,
      isExportingAll: false,
      showSurveyExportWarningModel: false,
      submitting: null,
      subscription: null,
      tempItemsArray: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filterVars: this.variables,
      initialDate: '',
      lastDate: '',
      json_fields: {
        'District': 'district',
        'Divisional secretariat': 'divisional_secretariat',
        'GN division': 'gn_division',
        'submittedAt': 'submittedAt',
        'completedAt': 'completedAt',
        'Access to well': 'access_to_well',
        'Age head household': 'age_head_household',
        'Are you a samurdhi beneficiary': 'are_you_a_samurdhi_beneficiary',
        'Are you receiving public monthly assistance currently': 'are_you_receiving_public_monthly_assistance_currently',
        'CreatedAt': 'createdAt',
        'Disabled dependents': 'disabled_dependents',
        'Do you have own house': 'do_you_have_own_house',
        'Do you have own land': 'do_you_have_own_land',
        'Elder dependents': 'elder_dependents',
        'Electricity in your house': 'electricity_in_your_house',
        'Employment primary job': 'employment_primary_job',
        'Family members receiving family public assistance total': 'family_members_receiving_family_public_assistance_total',
        'Family public assistance assistance disease': 'family_public_assistance_assistance_disease',
        'Family public assistance cancer': 'family_public_assistance_cancer',
        'Family public assistance disability assistance': 'family_public_assistance_disability_assistance',
        'Family public assistance elders assistance': 'family_public_assistance_elders_assistance',
        'Family public assistance kidney': 'family_public_assistance_kidney',
        'Family public assistance leprosy': 'family_public_assistance_leprosy',
        'Family public assistance spinal code injury': 'family_public_assistance_spinal_code_injury',
        'Family public assistance tb': 'family_public_assistance_tb',
        'Head household disabled': 'head_household_disabled',
        'Head of household': 'head_of_household',
        'Highest education head household': 'highest_education_head_household',
        'Interest self employment': 'interest_self_employment',
        'Mode': 'mode',
        'No of dependent': 'no_of_dependent',
        'Owner': 'owner',
        'Primary normal season': 'primary_normal_season',
        'Sanitation facility': 'sanitation_facility',
        'Sex of head household': 'sex_of_head_household',
        'survey Result Archive UserId': 'surveyResultArchiveUserId',
        'Total income family': 'total_income_family',
        'UpdatedAt': 'updatedAt',
        'Value of the samurdhi stamp': 'value_of_the_samurdhi_stamp',
        'Widowed reason': 'widowed_reason',
        'Younger fifteen year dependents': 'younger_fifteen_year_dependents',
        'Your house': 'your_house'
      },
    };
  },
  watch: {
    items: {
      handler(newValue) {
        localStorage.setItem('last_filter_items', JSON.stringify(newValue));
      },
      deep: true
    },
    filterVars: {
      handler(newValue) {
        localStorage.setItem('last_filter_variables', JSON.stringify(newValue));
      },
      deep: true
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.searchQuery ? this.searchItems.length / this.perPage : this.items?.length / this.perPage)
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const stop = start + this.perPage;

      return this.sortedItems.slice(start, stop);
    },
    sortedItems() {
      return this.searchQuery ? this.searchItems : this.items;
    },
  },
  created() {
    this.subscribeToUpdateSurveyExport();
  },
  methods: {
    async handleExport() {
      // this.handleExportCreate();
    },
    async handleReset() {
      this.variables.nextToken ? delete this.variables.nextToken : '';
      this.filterVars.nextToken ? delete this.this.filterVars.nextToken : '';
      this.variables.filter ? delete this.variables.filter : '';
      this.filterVars.filter ? delete this.filterVars.filter : '';
      this.handleResetFilter();
      this.currentPage = 1;
      this.totalItems = 0;
      await this.onLoadItems();
    },

    // export with filter
    async surveyExportExcel(type) {
      let filterValues = {};
      if (type === 'all') {
        this.isExportingAll = true;
        this.initialDate = '2021-12-06'
        this.lastDate = moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
      } else {
        this.isExporting = true;
        this.initialDate = moment(new Date(this.filterVariables.start_date)).format('YYYY-MM-DD'), //2023-07-01
            this.lastDate = moment(new Date(this.filterVariables.end_date)).format('YYYY-MM-DD')
        const filterMappings = {
          mode: 'mode',
          owner: 'owner',
          employment_primary_job: 'employment_primary_job',
          are_you_a_samurdhi_beneficiary: 'are_you_a_samurdhi_beneficiary',
          sex_of_head_household: 'sex_of_head_household',
          widowed_reason: 'widowed_reason',
          access_to_well: 'access_to_well',
          disabled_dependents: 'disabled_dependents',
          do_you_have_own_land: 'do_you_have_own_land',
          elder_dependents: 'elder_dependents',
          electricity_in_your_house: 'electricity_in_your_house',
          head_household_disabled: 'head_household_disabled',
          highest_education_head_household: 'highest_education_head_household',
          interest_self_employment: 'interest_self_employment',
          primary_normal_season: 'primary_normal_season',
          sanitation_facility: 'sanitation_facility',
          younger_fifteen_year_dependents: 'younger_fifteen_year_dependents',
          your_house: 'your_house',
          are_you_receiving_public_monthly_assistance_currently: 'are_you_receiving_public_monthly_assistance_currently',
          age_head_household: 'age_head_household',
          family_members_receiving_family_public_assistance_total: 'family_members_receiving_family_public_assistance_total',
          family_public_assistance_assistance_disease: 'family_public_assistance_assistance_disease',
          family_public_assistance_cancer: 'family_public_assistance_cancer',
          family_public_assistance_disability_assistance: 'family_public_assistance_disability_assistance',
          family_public_assistance_elders_assistance: 'family_public_assistance_elders_assistance',
          family_public_assistance_kidney: 'family_public_assistance_kidney',
          family_public_assistance_leprosy: 'family_public_assistance_leprosy',
          family_public_assistance_spinal_code_injury: 'family_public_assistance_spinal_code_injury',
          family_public_assistance_tb: 'family_public_assistance_tb',
          no_of_dependent: 'no_of_dependent',
          total_income_family: 'total_income_family',
          value_of_the_samurdhi_stamp: 'value_of_the_samurdhi_stamp',
          // completed_start_date: 'completed_start_date',
          // completed_end_date: 'completed_end_date',
        };

        for (const key of Object.keys(this.filterVariables)) {
          if (filterMappings[key]) {
            const filterKey = filterMappings[key];
            const filterValue = this.filterVariables[key];
            if (filterValue.condition) {
              switch (filterValue.condition) {
                case '=':
                  filterValues[filterKey] = {eq: parseInt(filterValue.from)};
                  break;
                case '>':
                  filterValues[filterKey] = {gt: parseInt(filterValue.from)};
                  break;
                case '<':
                  filterValues[filterKey] = {lt: parseInt(filterValue.from)};
                  break;
                case '>=':
                  filterValues[filterKey] = {ge: parseInt(filterValue.from)};
                  break;
                case '<=':
                  filterValues[filterKey] = {le: parseInt(filterValue.from)};
                  break;
                case 'between':
                  filterValues[filterKey] = {
                    between: [parseInt(filterValue.from), parseInt(filterValue.to)],
                  };
                  break;
              }
            } else {
              filterValues[filterKey] = {eq: filterValue};
            }
          }
        }


      }
      if (this.filterVariables['completed_start_date'] && this.filterVariables['completed_end_date']) {
        filterValues["completedAt"] = {
          between: [moment(new Date(this.filterVariables['completed_start_date'])).format('YYYY-MM-DD'), moment(new Date(this.filterVariables['completed_end_date'])).format('YYYY-MM-DD')],
        };
      }

      let values = {
        survey_id: this.filterVars.survey_id,
        export_id: uuidv4(),
        status: 'pending',
        filters: JSON.stringify(filterValues),
        initial_date: this.initialDate, //2023-07-01
        last_date: this.lastDate,
        exported_at: new Date(),
      };
      this.exportId = values.export_id
      if (type !== 'all') {
        delete values['custom_filter_index'];
        delete values['custom_filter_field'];
        delete values['custom_filter_value'];
        console.log(this.filterVariables, "this.filterVariables")
        if (this.filterVariables.gn_division !== undefined && this.filterVariables.gn_division !== null) {
          // values['custom_filter_index'] = 'byGNDivisionCompletedAt';
          values['custom_filter_index'] = 'byGNDivisionCreatedAt';
          values['custom_filter_field'] = 'gn_division';
          values['custom_filter_value'] = this.filterVariables.gn_division;
        } else if (
            this.filterVariables.divisional_secretariat !== undefined &&
            this.filterVariables.divisional_secretariat
        ) {
          // values['custom_filter_index'] = 'byDivisionalSecretariatCompletedAt';
          values['custom_filter_index'] = 'byDivisionalSecretariatCreatedAt';
          values['custom_filter_field'] = 'divisional_secretariat';
          values['custom_filter_value'] = this.filterVariables.divisional_secretariat;
        } else if (this.filterVariables.district !== undefined && this.filterVariables.district) {
          // values['custom_filter_index'] = 'byDistrictCompletedAt';
          values['custom_filter_index'] = 'byDistrictCreatedAt';
          values['custom_filter_field'] = 'district';
          values['custom_filter_value'] = this.filterVariables.district;
        }
      }

      const toast = useToast();

      try {
        await API.graphql(graphqlOperation(createSurveyExport, {
          input: values
        })).then(() => {
          this.exportId = values.export_id
        });
      } catch (error) {
        console.error(error);
        toast.error('Survey Export Failed.', {
          timeout: 5000,
        });
        this.isExporting = false;
        return;
      }
    },


    //export all
    // async surveyExportExcelAllDownload() {
    //   this.isExportingAll = true;
    //   let values = {
    //     survey_id: this.filterVars.survey_id,
    //     export_id: uuidv4(),
    //     status: 'pending',
    //     filters: {},
    //     initial_date: '2021-12-06', //2023-07-01
    //     last_date: moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
    //     exported_at: new Date(),
    //   };
    //   this.exportId = values.export_id
    //
    //   const toast = useToast();
    //
    //   try {
    //     await API.graphql(graphqlOperation(createSurveyExport, {
    //       input: values
    //     })).then(() => {
    //       this.exportId = values.export_id
    //     });
    //   } catch (error) {
    //     console.error(error);
    //     toast.error('Survey Export Failed.', {
    //       timeout: 5000,
    //     });
    //     this.isExportingAll = false;
    //     return;
    //   }
    // },

    subscribeToUpdateSurveyExport() {
      try {
        API.graphql(graphqlOperation(onUpdateSurveyExport)).subscribe({
          next: () => {
            this.downloadExport(this.exportId);
          },
          error: (error) => {
            console.warn(error);
          },
        });
      } catch (error) {
        console.error("An error occurred while subscribing to survey export updates:", error);
        this.isExporting = false;
      }
    },

    // async checkFileExists(exportId) {
    //   try {
    //     const response = await fetch(
    //         `https://n678jovwn6.execute-api.ap-southeast-1.amazonaws.com/production/export/${exportId}.csv`
    //     );
    //     return response.ok;
    //   } catch (error) {
    //     console.error('Error checking file existence:', error);
    //     return false;
    //   }
    // },

    async downloadExport(exportId) {
      try {
        const toast = useToast();
        const values = {
          survey_id: this.filterVars.survey_id,
          filter: {
            export_id: {
              eq: exportId,
            },
          },
          sortDirection: 'DESC',
        };
        try {
          const response = await API.graphql(graphqlOperation(listSurveyExports, values));
          this.surveyExports = response.data.listSurveyExports.items[0];
          if (this.surveyExports?.status === 'downloadable' && this.surveyExports.export_id == exportId) {
            toast.success('Survey Export Successfully Initiated.', {
              timeout: 5000,
            });
            const response = await API.get('exportDownload', `/export/${exportId}.csv`);
            if (response.success) {
              const a = document.createElement('a');
              a.href = response.url;
              // a.download = `${exportId}.csv`;
              a.download = 'survey-result-archive.csv';
              a.click();
              a.remove();
            }
          } else if (this.surveyExports?.status === 'failed') {
            this.showSurveyExportWarningModel = true
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isExporting = false;
        this.isExportingAll = false;
      }
    },


    async filterOnClick() {
      await this.setFilters();
    },
    onView(item) {
      this.$emit('view', item);
    },
    async setFilters() {
      const filterVariables = JSON.parse(JSON.stringify(this.filterVariables));
      if (filterVariables['start_date'] !== undefined && filterVariables['end_date'] !== undefined) {
        if (filterVariables['mode'] !== undefined && filterVariables.mode === 'draft') {
          this.filterVars['filter'] = {
            ...this.variables['filter'],
            ...{'createdAt': {'between': [filterVariables.start_date, filterVariables.end_date]}}
          }
        } else {
          this.filterVars['filter'] = {
            ...this.variables['filter'],
            ...{'createdAt': {'between': [filterVariables.start_date, filterVariables.end_date]}}
          }
        }
      }
      if (filterVariables['completed_start_date'] !== undefined && filterVariables['completed_start_date'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'completedAt': {'between': [filterVariables.completed_start_date, filterVariables.completed_end_date]}}
        }
      }
      if (filterVariables['mode'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'mode': {'eq': filterVariables.mode}}
        }
      }
      if (filterVariables['district'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'district': {'eq': filterVariables.district}}
        }
      }
      if (filterVariables['divisional_secretariat'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'divisional_secretariat': {'eq': filterVariables.divisional_secretariat}}
        }
      }
      if (filterVariables['gn_division'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'gn_division': {'eq': filterVariables.gn_division}}
        }
      }
      if (filterVariables['owner'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'owner': {'eq': filterVariables.owner}}
        }
      }
      if (filterVariables['employment_primary_job'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'employment_primary_job': {'eq': filterVariables.employment_primary_job}}
        }
      }
      if (filterVariables['are_you_a_samurdhi_beneficiary'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'are_you_a_samurdhi_beneficiary': {'eq': filterVariables.are_you_a_samurdhi_beneficiary}}
        }
      }
      if (filterVariables['sex_of_head_household'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'sex_of_head_household': {'eq': filterVariables.sex_of_head_household}}
        }
      }
      if (filterVariables['widowed_reason'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'widowed_reason': {'eq': filterVariables.widowed_reason}}
        }
      }
      if (filterVariables['access_to_well'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'access_to_well': {'eq': filterVariables.access_to_well}}
        }
      }
      if (filterVariables['sanitation_facility'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'sanitation_facility': {'eq': filterVariables.sanitation_facility}}
        }
      }
      if (filterVariables['electricity_in_your_house'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'electricity_in_your_house': {'eq': filterVariables.electricity_in_your_house}}
        }
      }
      if (filterVariables['disabled_dependents'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'disabled_dependents': {'eq': filterVariables.disabled_dependents}}
        }
      }
      if (filterVariables['elder_dependents'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'elder_dependents': {'eq': filterVariables.elder_dependents}}
        }
      }
      if (filterVariables['younger_fifteen_year_dependents'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'younger_fifteen_year_dependents': {'eq': filterVariables.younger_fifteen_year_dependents}}
        }
      }
      if (filterVariables['do_you_have_own_land'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'do_you_have_own_land': {'eq': filterVariables.do_you_have_own_land}}
        }
      }
      if (filterVariables['interest_self_employment'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'interest_self_employment': {'eq': filterVariables.interest_self_employment}}
        }
      }
      if (filterVariables['head_household_disabled'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'head_household_disabled': {'eq': filterVariables.head_household_disabled}}
        }
      }
      if (filterVariables['head_household_disabled'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'head_household_disabled': {'eq': filterVariables.head_household_disabled}}
        }
      }
      if (filterVariables['primary_normal_season'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'primary_normal_season': {'eq': filterVariables.primary_normal_season}}
        }
      }
      //no filter added
      if (filterVariables['do_you_have_own_house'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'do_you_have_own_house': {'eq': filterVariables.do_you_have_own_house}}
        }
      }
      if (filterVariables['highest_education_head_household'] !== undefined) {
        this.filterVars['filter'] = {
          ...this.variables['filter'],
          ...{'highest_education_head_household': {'eq': filterVariables.highest_education_head_household}}
        }
      }
      if (filterVariables['total_income_family'] !== undefined) {
        switch (filterVariables['total_income_family'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'total_income_family': {'eq': parseInt(filterVariables.total_income_family.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'total_income_family': {'gt': parseInt(filterVariables.total_income_family.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'total_income_family': {'lt': parseInt(filterVariables.total_income_family.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'total_income_family': {'ge': parseInt(filterVariables.total_income_family.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'total_income_family': {'le': parseInt(filterVariables.total_income_family.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'total_income_family': {'between': [parseInt(filterVariables.total_income_family.from), parseInt(filterVariables.total_income_family.to)]}}
            }
            break;
        }
      }
      if ('age_head_household' in filterVariables) {
        switch (filterVariables['age_head_household'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'age_head_household': {'eq': parseInt(filterVariables.age_head_household.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'age_head_household': {'gt': parseInt(filterVariables.age_head_household.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'age_head_household': {'lt': parseInt(filterVariables.age_head_household.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'age_head_household': {'ge': parseInt(filterVariables.age_head_household.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'age_head_household': {'le': parseInt(filterVariables.age_head_household.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'age_head_household': {'between': [parseInt(filterVariables.age_head_household.from), parseInt(filterVariables.age_head_household.to)]}}
            }
            break;
        }
      }
      if (filterVariables['value_of_the_samurdhi_stamp'] !== undefined) {
        switch (filterVariables['value_of_the_samurdhi_stamp'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'value_of_the_samurdhi_stamp': {'eq': parseInt(filterVariables.value_of_the_samurdhi_stamp.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'value_of_the_samurdhi_stamp': {'gt': parseInt(filterVariables.value_of_the_samurdhi_stamp.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'value_of_the_samurdhi_stamp': {'lt': parseInt(filterVariables.value_of_the_samurdhi_stamp.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'value_of_the_samurdhi_stamp': {'ge': parseInt(filterVariables.value_of_the_samurdhi_stamp.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'value_of_the_samurdhi_stamp': {'le': parseInt(filterVariables.value_of_the_samurdhi_stamp.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'value_of_the_samurdhi_stamp': {'between': [parseInt(filterVariables.value_of_the_samurdhi_stamp.from), parseInt(filterVariables.value_of_the_samurdhi_stamp.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_elders_assistance'] !== undefined) {
        switch (filterVariables['family_public_assistance_elders_assistance'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_elders_assistance': {'eq': parseInt(filterVariables.family_public_assistance_elders_assistance.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_elders_assistance': {'gt': parseInt(filterVariables.family_public_assistance_elders_assistance.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_elders_assistance': {'lt': parseInt(filterVariables.family_public_assistance_elders_assistance.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_elders_assistance': {'ge': parseInt(filterVariables.family_public_assistance_elders_assistance.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_elders_assistance': {'le': parseInt(filterVariables.family_public_assistance_elders_assistance.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_elders_assistance': {'between': [parseInt(filterVariables.family_public_assistance_elders_assistance.from), parseInt(filterVariables.family_public_assistance_elders_assistance.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_members_receiving_family_public_assistance_total'] !== undefined) {
        switch (filterVariables['family_members_receiving_family_public_assistance_total'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_members_receiving_family_public_assistance_total': {'eq': parseInt(filterVariables.family_members_receiving_family_public_assistance_total.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_members_receiving_family_public_assistance_total': {'gt': parseInt(filterVariables.family_members_receiving_family_public_assistance_total.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_members_receiving_family_public_assistance_total': {'lt': parseInt(filterVariables.family_members_receiving_family_public_assistance_total.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_members_receiving_family_public_assistance_total': {'ge': parseInt(filterVariables.family_members_receiving_family_public_assistance_total.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_members_receiving_family_public_assistance_total': {'le': parseInt(filterVariables.family_members_receiving_family_public_assistance_total.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_members_receiving_family_public_assistance_total': {'between': [parseInt(filterVariables.family_members_receiving_family_public_assistance_total.from), parseInt(filterVariables.family_members_receiving_family_public_assistance_total.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_disability_assistance'] !== undefined) {
        switch (filterVariables['family_public_assistance_disability_assistance'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_disability_assistance': {'eq': parseInt(filterVariables.family_public_assistance_disability_assistance.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_disability_assistance': {'gt': parseInt(filterVariables.family_public_assistance_disability_assistance.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_disability_assistance': {'lt': parseInt(filterVariables.family_public_assistance_disability_assistance.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_disability_assistance': {'ge': parseInt(filterVariables.family_public_assistance_disability_assistance.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_disability_assistance': {'le': parseInt(filterVariables.family_public_assistance_disability_assistance.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_disability_assistance': {'between': [parseInt(filterVariables.family_public_assistance_disability_assistance.from), parseInt(filterVariables.family_public_assistance_disability_assistance.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_assistance_disease'] !== undefined) {
        switch (filterVariables['family_public_assistance_assistance_disease'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_assistance_disease': {'eq': parseInt(filterVariables.family_public_assistance_assistance_disease.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_assistance_disease': {'gt': parseInt(filterVariables.family_public_assistance_assistance_disease.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_assistance_disease': {'lt': parseInt(filterVariables.family_public_assistance_assistance_disease.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_assistance_disease': {'ge': parseInt(filterVariables.family_public_assistance_assistance_disease.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_assistance_disease': {'le': parseInt(filterVariables.family_public_assistance_assistance_disease.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_assistance_disease': {'between': [parseInt(filterVariables.family_public_assistance_assistance_disease.from), parseInt(filterVariables.family_public_assistance_assistance_disease.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_kidney'] !== undefined) {
        switch (filterVariables['family_public_assistance_kidney'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_kidney': {'eq': parseInt(filterVariables.family_public_assistance_kidney.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_kidney': {'gt': parseInt(filterVariables.family_public_assistance_kidney.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_kidney': {'lt': parseInt(filterVariables.family_public_assistance_kidney.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_kidney': {'ge': parseInt(filterVariables.family_public_assistance_kidney.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_kidney': {'le': parseInt(filterVariables.family_public_assistance_kidney.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_kidney': {'between': [parseInt(filterVariables.family_public_assistance_kidney.from), parseInt(filterVariables.family_public_assistance_kidney.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_tb'] !== undefined) {
        switch (filterVariables['family_public_assistance_tb'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_tb': {'eq': parseInt(filterVariables.family_public_assistance_tb.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_tb': {'gt': parseInt(filterVariables.family_public_assistance_tb.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_tb': {'lt': parseInt(filterVariables.family_public_assistance_tb.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_tb': {'ge': parseInt(filterVariables.family_public_assistance_tb.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_tb': {'le': parseInt(filterVariables.family_public_assistance_tb.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_tb': {'between': [parseInt(filterVariables.family_public_assistance_tb.from), parseInt(filterVariables.family_public_assistance_tb.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_leprosy'] !== undefined) {
        switch (filterVariables['family_public_assistance_leprosy'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_leprosy': {'eq': parseInt(filterVariables.family_public_assistance_leprosy.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_leprosy': {'gt': parseInt(filterVariables.family_public_assistance_leprosy.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_leprosy': {'lt': parseInt(filterVariables.family_public_assistance_leprosy.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_leprosy': {'ge': parseInt(filterVariables.family_public_assistance_leprosy.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_leprosy': {'le': parseInt(filterVariables.family_public_assistance_leprosy.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_leprosy': {'between': [parseInt(filterVariables.family_public_assistance_leprosy.from), parseInt(filterVariables.family_public_assistance_leprosy.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_cancer'] !== undefined) {
        switch (filterVariables['family_public_assistance_cancer'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_cancer': {'eq': parseInt(filterVariables.family_public_assistance_cancer.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_cancer': {'gt': parseInt(filterVariables.family_public_assistance_cancer.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_cancer': {'lt': parseInt(filterVariables.family_public_assistance_cancer.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_cancer': {'ge': parseInt(filterVariables.family_public_assistance_cancer.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_cancer': {'le': parseInt(filterVariables.family_public_assistance_cancer.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_cancer': {'between': [parseInt(filterVariables.family_public_assistance_cancer.from), parseInt(filterVariables.family_public_assistance_cancer.to)]}}
            }
            break;
        }
      }
      if (filterVariables['family_public_assistance_spinal_code_injury'] !== undefined) {
        switch (filterVariables['family_public_assistance_spinal_code_injury'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_spinal_code_injury': {'eq': parseInt(filterVariables.family_public_assistance_spinal_code_injury.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_spinal_code_injury': {'gt': parseInt(filterVariables.family_public_assistance_spinal_code_injury.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_spinal_code_injury': {'lt': parseInt(filterVariables.family_public_assistance_spinal_code_injury.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_spinal_code_injury': {'ge': parseInt(filterVariables.family_public_assistance_spinal_code_injury.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_spinal_code_injury': {'le': parseInt(filterVariables.family_public_assistance_spinal_code_injury.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'family_public_assistance_spinal_code_injury': {'between': [parseInt(filterVariables.family_public_assistance_spinal_code_injury.from), parseInt(filterVariables.family_public_assistance_spinal_code_injury.to)]}}
            }
            break;
        }
      }
      if (filterVariables['no_of_dependent'] !== undefined) {
        switch (filterVariables['no_of_dependent'].condition) {
          case '=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'no_of_dependent': {'eq': parseInt(filterVariables.no_of_dependent.from)}}
            }
            break;
          case '>':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'no_of_dependent': {'gt': parseInt(filterVariables.no_of_dependent.from)}}
            }
            break;
          case '<':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'no_of_dependent': {'lt': parseInt(filterVariables.no_of_dependent.from)}}
            }
            break;
          case '>=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'no_of_dependent': {'ge': parseInt(filterVariables.no_of_dependent.from)}}
            }
            break;
          case '<=':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'no_of_dependent': {'le': parseInt(filterVariables.no_of_dependent.from)}}
            }
            break;
          case 'between':
            this.filterVars['filter'] = {
              ...this.variables['filter'],
              ...{'no_of_dependent': {'between': [parseInt(filterVariables.no_of_dependent.from), parseInt(filterVariables.family_public_assistance_spinal_code_injury.to)]}}
            }
            break;
        }
      }
      this.variables.nextToken ? delete this.variables.nextToken : '';
      this.filterVars.nextToken ? delete this.filterVars.nextToken : '';
      this.currentPage = 1;
      this.totalItems = 0;
      await this.onLoadItems();
    },
    onSearch(value) {
      this.searchQuery = value;
      this.currentPage = 1;
      this.searchItems = this.items?.filter(function (item) {
        return item.head_of_household ? item.head_of_household.toLowerCase().includes(value.toLowerCase()) : '';
      })
    },
    onPaginate() {
      this.onLoadItems();
    },
    async onLoadItems(nextToken) {
      try {
        if (this.query) {
          this.isLoading = true;
          const variables = this.filterVars;
          variables.limit = 300;
          if (nextToken) {
            variables.nextToken = nextToken;
          }

          await API.graphql(graphqlOperation(this.query, JSON.parse(JSON.stringify(variables)))).then(async ({data}) => {
            const dataItems = data[this.queryKey] ? data[this.queryKey] : [];
            if (!variables.nextToken) {
              this.items = [];
            }
            this.totalItems = this.totalItems + (dataItems.items ? dataItems.items.length : 0);
            this.items = this.items.concat(dataItems.items ? dataItems.items : []);
            console.log(this.items.length, "length")
            if (dataItems.nextToken) {
              await this.onLoadItems(dataItems.nextToken);
            }
          });
        }
        this.isLoading = false;
      } catch (error) {
        if (error.data[this.queryKey].nextToken) {
          await this.onLoadItems(error.data[this.queryKey].nextToken);
        }
        this.error = error.data?.message || error.statusText;
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    var last_filter_items = JSON.parse(localStorage.getItem('last_filter_items') || '[]');

    if (last_filter_items.length > 0) {
      this.items = last_filter_items;
      this.totalItems = this.items.length;
    } else {
      await this.onLoadItems();
    }

    this.subscription = Amplify.API.graphql(
        graphqlOperation(this.subscriptionGql)
    ).subscribe({
      next: () => {
        this.onLoadItems();
        this.submitting = null;
      },
      error: error => console.warn(error)
    });
  },
  async beforeUnmount() {
    this.subscription.unsubscribe();
  }
};

</script>
