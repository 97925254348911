<template>
  <div>
    {{ valueData }}
    <SearchDropdown
      :key="`dd_${options.length}_${valueData ? valueData.value : ''}`"
      :options="options"
      @selected="values => $emit('selected', values ? values.value : null)"
      :value="valueData"
      :placeholder="placeholder"
      ref="dropdown"
    />
  </div>
</template>

<script>
import SearchDropdown from '../../../components/SearchDropdown';
import API, {graphqlOperation} from '@aws-amplify/api';
import map from 'lodash-es/map';
import find from 'lodash-es/find';
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
    },
    query: {
      type: String,
    },
  },
  components: {
    SearchDropdown,
  },
  computed: {
    getValue() {
      console.log(this.value, 'this.value');
      return find(this.options, {id: this.value});
    },
  },
  data() {
    return {
      options: [],
      valueData: null,
    };
  },
  methods: {
    async getData() {
      const {data} = await API.graphql(graphqlOperation(this.query));
      this.options = map(data.listUsers.items, item => {
        return {
          name: item.username,
          value: item.id,
        };
      });
    },
    async setValue() {
      if (!this.options.length) {
        await this.getData();
      }
      const value = find(this.options, {id: this.value});
      this.valueData = value ? value : null;
    },
  },
  async mounted() {
    await this.getData();
    const value = find(this.options, {id: this.value});
    this.valueData = value ? value : null;
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    value: {
      immediate: true,
      handler(current) {
        if (!current) {
          // eslint-disable-next-line no-prototype-builtins
          this.$refs.hasOwnProperty('dropdown') ? this.$refs.dropdown.clearDD() : null;
        }
        this.setValue();
      },
    },
  },
};
</script>
