<template>
  <button type="button" @click.stop="$emit('action')" class="px-3 py-2 text-left w-full">
    {{ action.text }}
  </button>
</template>

<script>
export default {
  props: {
    url: String,
    item: Object,
    action: Object,
  },
};
</script>
