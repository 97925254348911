import {listUsers} from "../../graphql/queries";

export const filters = [
    {
        name: 'start_date',
        display_name: 'Created Start Date',
        field: 'start_date',
        range: 'createdAt',
        type: 'date',
        component: 'DatePicker',
        depend: 'end_date',
    },
    {
        name: 'end_date',
        display_name: 'Created End Date',
        field: 'end_date',
        type: 'date',
        component: 'DatePicker',
        hiddenFilter: true,
    },
    {
        name: 'owner',
        display_name: 'Owner',
        field: 'owner',
        type: 'autocomplete',
        component: 'SearchDropdown',
        query: listUsers,
    },
    {
        name: 'completed_start_date',
        display_name: 'Completed Start Date',
        field: 'completed_start_date',
        range: 'completedAt',
        type: 'date',
        component: 'DatePicker',
        depend: 'completed_end_date',
    },
    {
        name: 'completed_end_date',
        display_name: 'Completed End Date',
        field: 'completed_end_date',
        type: 'date',
        component: 'DatePicker',
        hiddenFilter: true,
    },
    {
        name: 'mode',
        display_name: 'Mode',
        field: 'mode',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    '',
                    "draft",
                    "submitted",
                    "processed",
                ],
                key: 'data',
            },
        ],
    },

    {
        name: 'district',
        display_name: 'District',
        field: 'district',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Jaffna', 'Mannar', 'Vavuniya', 'Mullaitivu', 'Kilinochchi'],
                key: 'data',
            },
        ],
    },
    {
        name: 'divisional_secretariat',
        display_name: 'Divisional Secretariat',
        field: 'divisional_secretariat',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    'Island North-Kayts',
                    'Valikamam West-Chankanai',
                    'Valikamam South-West-Sandilipay',
                    'Valikamam North-Tellipallai',
                    'Valikamam South-Uduvil',
                    'Valikamam-East- Kopayi',
                    'Vadamaradchy South-West, Karaveddy',
                    'Vadamaradchi East-Maruthnkerny',
                    'Vadamaradchi North-Point Pedro',
                    'Thenmaradchi-Chavakachcheri',
                    'Nallur',
                    'Jaffna',
                    'Island South ,Velanai',
                    'Delft',
                    'Karainagar',
                ],
                key: 'Jaffna',
            },
            {
                values: ['Mannar Town', 'Manthai West', 'Madhu', 'Nanaddan', 'Musalai'],
                key: 'Mannar',
            },
            {
                values: ['Vavuniya North', 'Vavuniya South', 'Vavuniya', 'Vengalacheddikulam'],
                key: 'Vavuniya',
            },
            {
                values: [
                    'Thunukkai',
                    'Manthai East',
                    'Puthukudiyiruppu',
                    'Oddusuddan',
                    'Maritimepattu',
                    'Welioya',
                ],
                key: 'Mullaitivu',
            },
            {
                values: ['Pachchilaipalli', 'Kandavalai', 'Karachchi', 'Poonakary'],
                key: 'Kilinochchi',
            },
        ],
        value_depend_field: 'district',
    },
    {
        name: 'gn_division',
        display_name: 'GN Division',
        field: 'gn_division',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    'Analaitivu North-J/37',
                    'Analaitivu South-J/38',
                    'Eluvaitivu-J/39',
                    'Kayts-J/49',
                    'Paruthiyadaippu-J/50',
                    'Karampon-J/51',
                    'Karampon East-J/52',
                    'Karampon South East-J/53',
                    'Karampon West-J/54',
                    'Naranthanai North-J/55',
                    'Naranthanai North West-J/56',
                    'Naranthanai-J/57',
                    'Naranthanai North West-J/58',
                    'Seruvil-J/59',
                    'Puliyankoodal-J/60',
                ],
                key: 'Island North-Kayts',
            },
            {
                values: [
                    'Vaddukoddai East-J/157',
                    'Vaddukoddai North-J/158',
                    'Sangaraththai-J/159',
                    'Araly West-J/160',
                    'Araly Centre-J/161',
                    'Araly South-J/162',
                    'Araly East-J/163',
                    'Araly North-J/164',
                    'Vaddu South-J/165',
                    'Vaddukoddai South West-J/166',
                    'Vaddukoddai West-J/167',
                    'Tholpuram East-J/168',
                    'Tholpuram West-J/169',
                    'Ponnalai-J/170',
                    'Moolai-J/171',
                    'Chulipuram West-J/172',
                    'Chulipuram Centre-J/173',
                    'Chulipuram East-J/174',
                    'Pannakam-J/175',
                    'Panipulam-J/176',
                    'Sithankerny-J/177',
                    'Chankanai East-J/178',
                    'Chankanai West-J/179',
                    'Chankanai South-J/180',
                    'Chankanai Centre-J/181',
                ],
                key: 'Valikamam West-Chankanai',
            },
            {
                values: [
                    'Suthumalai North-J/129',
                    'Suthumalai South-J/130',
                    'Savatkaddu-J/131',
                    'Uyarapulam-J/132',
                    'Anaikoddadai-J/133',
                    'Navali North-J/134',
                    'Navali East-J/135',
                    'Navali South-J/136',
                    'Manipay North-J/137',
                    'Manipay East-J/138',
                    'Manipay South-J/139',
                    'Manipay West-J/140',
                    'Sandilipay North-J/141',
                    'Sandilipay Centre-J/142',
                    'Sandilipay West-J/143',
                    'Mahiyapiddy-J/144',
                    'Vadaliyadaippu-J/145',
                    'Pandaitharippu-J/146',
                    'Piranpattu-J/147',
                    'Chillalai North-J/148',
                    'Chillalai South-J/149',
                    'Mathagal East-J/150',
                    'Mathagal South-J/151',
                    'Mathagal West-J/152',
                    'Periyavilan-J/153',
                    'Marisankoodal-J/154',
                    'Illavalai-J/155',
                    'Mullanai-J/156',
                ],
                key: 'Valikamam South-West-Sandilipay',
            },
            {
                values: [
                    'Mallakam South-J/212',
                    'Mallakam Centre-J/213',
                    'Mallakam North-J/214',
                    'Alaveddy North-J/215',
                    'Alaveddy Centre-J/216',
                    'Alaveddy East-J/217',
                    'Ganeswaram-J/218',
                    'Alaveddy South-J/219',
                    'Alaveddy West-J/220',
                    'Illavalai North-J/221',
                    'Illavalai North West-J/222',
                    'Viththagapuram-J/223',
                    'Ponnalai-J/224',
                    'Kollankalddy-J/225',
                    'Naguleswaram-J/226',
                    'Tellipalai East-J/227',
                    'Tellipalai-J/228',
                    'Thurkkapuram-J/229',
                    'Thanthai Selvapuram-J/230',
                    'Maviddapuram-J/231',
                    'Maviddapuram South-J/232',
                    'Kankesanthurai West-J/233',
                    'Kankesanthural Centre-J/234',
                    'Kankesanthural South-J/235',
                    'Palaiveemankamam North-J/236',
                    'Palaiveemankamam South-J/237',
                    'Kadduvan-J/238',
                    'Kadduvan West-J/239',
                    'Thenyamalai-J/240',
                    'Varuthlaivilan-J/241',
                    'Kurumpasiddy-J/242',
                    'Kurumpasiddy East-J/243',
                    'Vasavilan-J/244',
                    'Vasavilan West-J/245',
                    'Magiliddy North-J/246',
                    'Thaiyiddy East-J/247',
                    'Mayiliddythurai South-J/248',
                    'Thaiyiddy North-J/249',
                    'Thaiyiddy South-J/250',
                    'Mayiliddythurai North-J/251',
                    'Palaly South-J/252',
                    'Palaly East-J/253',
                    'Palaly North-J/254',
                    'Palaly North West-J/255',
                    'Palaly West-J/256',
                ],
                key: 'Valikamam North-Tellipallai',
            },
            {
                values: [
                    'Uduvil South West-J/182',
                    'Uduvil South East-J/183',
                    'Uduvil Centre-J/184',
                    'Uduvil Centre North-J/185',
                    'Uduvil North-J/186',
                    'Sanguvely-J/187',
                    'Inuvil South West-J/188',
                    'Inuvil East-J/189',
                    'Inuvil North East-J/190',
                    'Inuvil West-J/191',
                    'Thavady South-J/192',
                    'Thavady East-J/193',
                    'Thavady North-J/194',
                    'Chunnakam Town North-J/195',
                    'Chunnakam Town South-J/196',
                    'Chunnakam Town East-J/197',
                    'Chunnakam Town Centre-J/198',
                    'Chunnakam Town West-J/199',
                    'Kantharodai-J/200',
                    'Eralalai West-J/201',
                    'Eralalas South West-J/202',
                    'Eralalai South-J/203',
                    'Eralalai East-J/204',
                    'Eralalai North-J/205',
                    'Eralalai Centre-J/206',
                    'Punnalaikadduvan South-J/207',
                    'Punnalaikadduvan North-J/208',
                    'Eevinai-J/209',
                    'Kuppilan South-J/210',
                    'Kuppilan North-J/211',
                ],
                key: 'Valikamam South-Uduvil',
            },
            {
                values: [
                    'Irupalai South-J/257',
                    'Irupalai East-J/258',
                    'Kalviyankadu-J/259',
                    'Kopay South-J/260',
                    'Kopay Centre-J/261',
                    'Kopay North-J/262',
                    'Urumpirai West-J/263',
                    'Urumpirai North-J/264',
                    'Urumpirai South-J/265',
                    'Urumpirai East-J/266',
                    'Urelu-J/267',
                    'Neervely South-J/268',
                    'Neervely North-J/269',
                    'Neervely West-J/270',
                    'Sirupiddy East-J/271',
                    'Sirupiddy West-J/272',
                    'Puttur West-J/273',
                    'Puttur North-J/274',
                    'Navakiri-J/275',
                    'Avarangal East-J/276',
                    'Avarangal West-J/277',
                    'Puttur East-J/278',
                    'Achchehelu-J/279',
                    'Vatharawaththai-J/280',
                    'Pathaimeny-J/281',
                    'Thambalai Kathiripay-J/282',
                    'Idaikadu-J/283',
                    'Valalai-J/284',
                    'Atchuvely North-J/285',
                    'Atchavely South-J/286',
                    'Atchuvely West-J/287',
                ],
                key: 'Valikamam-East- Kopayi',
            },
            {
                values: [
                    'Karanavai South-J/348',
                    'Karanavai West-J/349',
                    'Karanavai-J/350',
                    'Karanavai East-J/351',
                    'Udupiddy-J/352',
                    'Udupiddy North-J/353',
                    'Udupiddy South-J/354',
                    'Valvetty-J/355',
                    'Velvetty Centre-J/356',
                    'Samarapaku-J/357',
                    'Imayanan-J/358',
                    'Imayanan West-J/359',
                    'Karanavai  North-J/360',
                    'Karanavai North West-J/361',
                    'Karanavai Centre-J/362',
                    'Karaveddy West-J/363',
                    'Karaveddy North-J/364',
                    'Karaveddy South-J/365',
                    'Maththony-J/366',
                    'Karaveddy Centre-J/367',
                    'Karaveddy East-J/368',
                    'Kaddaiveli-J/369',
                    'Thunnalai South-J/370',
                    'Thunnalai East-J/371',
                    'Thunnalai-J/372',
                    'Thunnalai Centre-J/373',
                    'Thunnalai West-J/374',
                    'Nelliady North-J/375',
                    'Nelliady-J/376',
                    'Nellaidy East-J/377',
                    'Alvai-J/378',
                    'Alvai South-J/379',
                    'Alvai East-J/380',
                    'Aththai-J/381',
                    'Kapputhoo-J/382',
                ],
                key: 'Vadamaradchy South-West, Karaveddy',
            },
            {
                values: [
                    'Manatkada-J/418',
                    'Kudaththanai-J/419',
                    'Kudaththanai Karaiyoor-North-J/420',
                    'Potpathy-J/421',
                    'Ampan-J/422',
                    'Nagarkovil East-J/423',
                    'Nagarkovil West-J/424',
                    'Nagarkovil South-J/425',
                    'Chempianpattu North-J/426',
                    'Chempianpattu South-J/427',
                    'Maruthankerny-J/428',
                    'Vaththirayan-J/429',
                    'Uduththurai-J/430',
                    'Aliyavalai-J/431',
                    'Vettilaikerny-J/432',
                    'Mullain-J/433',
                    'Pokkaruppu-J/434',
                    'Chundikulam-J/435',
                ],
                key: 'Vadamaradchi East-Maruthnkerny',
            },
            {
                values: [
                    'Thondamannaru South-J/383',
                    'Thondamanmaru North-J/384',
                    'Karudavil South-J/385',
                    'Karudavil North-J/386',
                    'Karudavil East-J/387',
                    'Valvettithurai North West-J/388',
                    'Valvettithurai North Centre-J/389',
                    'Valvettithurai North East-J/390',
                    'Valvettithurai South West-J/391',
                    'Valvettithurai South East-J/392',
                    'Polikandy West-J/393',
                    'Polikandy East-J/394',
                    'Polikandy South-J/395',
                    'Alvai West-J/396',
                    'Alvai North West-J/397',
                    'Alvai North Centre-J/398',
                    'Viyaparimoolai-J/399',
                    'Alvai North-J/400',
                    'Point Pedro-J/401',
                    'Point Pedro South-J/402',
                    'Point Pedro East-J/403',
                    'Thumpalai-J/404',
                    'Thumpalai East-J/405',
                    'Katkovalam-J/406',
                    'Puloly North-J/407',
                    'Puloly North East-J/408',
                    'Puloly Centre-J/409',
                    'Puloly West-J/410',
                    'Puloly South West-J/411',
                    'Puloly North West-J/412',
                    'Puloly East-J/413',
                    'Puloly South-J/414',
                    'Manthaikai-J/415',
                    'Vallipuram-J/416',
                    'Thunnali North-J/417',
                ],
                key: 'Vadamaradchi North-Point Pedro',
            },
            {
                values: [
                    'Kaithady North-J/288',
                    'Kaithady East-J/289',
                    'Kaithady Centre-J/290',
                    'Kaithady South-J/291',
                    'Kaithady South East-J/292',
                    'Kaithady West-J/293',
                    'Navatkuly West-J/294',
                    'Navatkuly East-J/295',
                    'Kogilakandy-J/296',
                    'Kaithady Navatkuly-J/297',
                    'Maravanpulo-J/298',
                    'Thanankillappu-J/299',
                    'Chavakachcheri Town-J/300',
                    'Kovitkudyiruppu-J/301',
                    'Sankathanai-J/302',
                    'Chavakachcheri North-J/303',
                    'Manduvil-J/304',
                    'Kalvayal-J/305',
                    'Nunavil East-J/306',
                    'Nunavil Centre-J/307',
                    'Nunavil West-J/308',
                    'Kaithady Nunavil- J/309',
                    'Themattuvil-J/310',
                    'Maddavil Nunavil-J/311',
                    'Madduvil Centre-J/312',
                    'Madduvil North-J/313',
                    'Madduvil East-J/314',
                    'Chandrapuram-J/315',
                    'Sarasalai South-J/316',
                    'Sarasalai North-J/317',
                    'Meesalai East-J/318',
                    'Meesalai West-J/319',
                    'Ramavil-J/320',
                    'Meesalai North-J/321',
                    'Allaral-J/322',
                    'Vellampokkaddy-J/323',
                    'Kachchai-J/324',
                    'Palavi-J/325',
                    'Kodikamam North-J/326',
                    'Kodikamam Centre-J/327',
                    'Kodikamam South-J/328',
                    'Ushan-J/329',
                    'Karampaham-J/330',
                    'Vidaththalpallai-J/331',
                    'Ketpeli-J/332',
                    'Eluthumaadwarl South-J/333',
                    'Eluthumaadwart North-J/334',
                    'Mirusuvil North-J/335',
                    'Mirusuvil South-J/336',
                    'Kudamiyan-J/337',
                    'Navatkadu-J/338',
                    'Varany North-J/339',
                    'Maseri-J/340',
                    'Idaikurichchi-J/341',
                    'Karampaikurichchi-J/342',
                    'Varani Iyattalai-J/343',
                    'Thavalai lyattalai-J/344',
                    'Manthuvil East-J/345',
                    'Manthuvil West-J/346',
                    'Manthuvil North-J/347',
                ],
                key: 'Thenmaradchi-Chavakachcheri',
            },
            {
                values: [
                    'Ariyalai South East-J/89',
                    'Aniyalai East-J/90',
                    'Ariyalai North West-J/91',
                    'Ariyalai Centre West-J/92',
                    'Anyalai South West-J/93',
                    'Ariyalai Centre North-J/94',
                    'Ariyalai Centre-J/95',
                    'Ariyalai Centre South-J/96',
                    'Iyanarkovillady-J/97',
                    'Vannarpannai North-J/98',
                    'Vannarpannai North West-J/99',
                    'Vannarpannai North East-J/100',
                    'Neeraviyady-J/101',
                    'Kandarmadam North West-J/102',
                    'Kandarmadam North East-J/103',
                    'Kandarmadam South West-J/104',
                    'Kandarmadam South East-J/105',
                    'Nallur North-J/106',
                    'Nallur Rajathani- J/107',
                    'Nallur-J/108',
                    'Sangilian Thoppu-J/109',
                    'Thirunelvely West-J/110',
                    'Thirunelvely Centre South-J/111',
                    'Thirunelvely South East-J/112',
                    'Thirunelvely North East-J/113',
                    'Thirunelvely Centre North-J/114',
                    'Kondavil North West-J/115',
                    'Kondasil South West-J/116',
                    'Koodavil Centre West-J/117',
                    'Kondavil Centre East-J/118',
                    'Koodavil North East-J/119',
                    'Kondavil South East-J/120',
                    'Kokkuvil North East-J/121',
                    'Kokkuvil East-J/122',
                    'Kokkuvil South East-J/123',
                    'Kokkuvil North West-J/124',
                    'Kokkuvil West-J/125',
                    'Kokkuvil Centre East-J/126',
                    'Kokkuvil South West-J/127',
                    'Kokkuvil Centre West-J/128',
                ],
                key: 'Nallur',
            },
            {
                values: [
                    'Nedunkulam- J/61',
                    'Columbuthurai East-J/62',
                    'Columbutharai West-J/63',
                    'Passaiyoor East-J/64',
                    'Passaiyoor West-J/65',
                    'Eachchamoddai- J/66',
                    'Thirumagar-J/67',
                    'Reclamation East-J/68',
                    'Reclamation West-J/69',
                    'Gurunagar East-J/70',
                    'Gurunagar West-J/71',
                    'Small Bazzar-J/72',
                    'Jaffna Town West-J/73',
                    'Jaffna Town East-J/74',
                    'Chundakali South-J/75',
                    'Chundakul North-J/76',
                    'Maruthady-J/77',
                    'Aththiyady-J/78',
                    'Sirampiady-J/79',
                    'Grand Bazzar-J/80',
                    'Fort-J/81',
                    'Vannarponnai-J/82',
                    'Koddady-J/83',
                    'Navanthurai South-J/84',
                    'Navanthurai North-J/85',
                    'Moor Street South-J/86',
                    'Moor Street North-J/87',
                    'New Moor Street-J/88',
                ],
                key: 'Jaffna',
            },
            {
                values: [
                    'Mandaitivu East-J/07',
                    'Mandaitivu West-J/08',
                    'Mandaitivu South-J/09',
                    'Allaipiddy-J/10',
                    'Mankumpan-J/11',
                    'Velanai North-J/12',
                    'Velanai North East-J/13',
                    'Velanai East-J/14',
                    'Velanai South East-J/15',
                    'Velana East Centre-J/16',
                    'Velanai South-J/17',
                    'Velanai West Centre-J/18',
                    'Velanai West-J/19',
                    'Saravanai East-J/20',
                    'Saravanai West-J/21',
                    'Pungudutivu North East-J/22',
                    'Pungudutivu East-J/23',
                    'Pungudutivu South East-J/24',
                    'Pungudutivu East South-J/25',
                    'Pungudutive South-J/26',
                    'Pungudative North-J/27',
                    'Pungudutivu Centre North-J/28',
                    'Pungudutivu South West-J/29',
                    'Punguditivu Centre West-J/30',
                    'Punguditivu Centre East-J/31',
                    'Pungudutivu North West-J/32',
                    'Pungudutivu West-J/33',
                    'Nainativu North-J/34',
                    'Nainativu Centre-J/35',
                    'Nainativu South-J/36',
                ],
                key: 'Island South ,Velanai',
            },
            {
                values: [
                    'Delft West-J/01',
                    'Delft South-J/02',
                    'Delft Centre West-J/03',
                    'Delft Centre-J/04',
                    'Delft Centre East-J/05',
                    'Delft East-J/06',
                ],
                key: 'Delft',
            },
            {
                values: [
                    'Karainagar West-J/40',
                    'Karainagar North West-J/41',
                    'Karainagar East-J/42',
                    'Karainagar South East-J/43',
                    'Karainagar South-J/44',
                    'Karainagar South West-J/45',
                    'Karainagar North-J/46',
                    'Karainagar North East-J/47',
                    'Karainagar Centre-J/48',
                ],
                key: 'Karainagar',
            },
            {
                values: [
                    'Talaimannar Village North-MNR/48',
                    'Talaimannur Village South-MNR/49',
                    'Talaimannar pier West-MNR/50',
                    'Talaimannar pier East-MNR/51',
                    'Talaimannar-MNR/52',
                    'Kaddukarankudiyiruppu-MNR/53',
                    'Thullukudiyiruppu-MNR/54',
                    'Pesalai west-MNR/55',
                    'Pesalai South-MNR/56',
                    'Pesalai North-MNR/57',
                    'Siruthoppu-MNR/58',
                    'Periya kerisal-MNR/59',
                    'Olaithodduwai-MNR/60',
                    'Pthukudiyiruppu-MNR/61',
                    'Thoddawely-MNR/62',
                    'Erukkalampiddy West-MNR/63',
                    'Erukkalampiddy North-MNR/64',
                    'Erukkalampiddy East-MNR/65',
                    'Erukkalampiddy South-MNR/66',
                    'Erukkalampiddy Central-MNR/67',
                    'Tharapuram west-MNR/68',
                    'Tharapuram East-MNR/69',
                    'Thalvpadu-MNR/70',
                    'Pattim-MNR/71',
                    'Eluthoor-MNR/72',
                    'South bar-MNR/73',
                    'Emil Nagar-MNR/74',
                    'Chavatkaddu-MNR/75',
                    'Panankaddikoddu west-MNR/76',
                    'Panankaddikoddu East-MNR/77',
                    'Sinnakadai-MNR/78',
                    'Pettah-MNR/79',
                    'Periyakadai-MNR/80',
                    'Moor Street-MNR/81',
                    'Uppukulum North-MNR/82',
                    'Uppukulam South-MNR/83',
                    'Pallimunai East-MNR/84',
                    'Pallimunai West-MNR/85',
                    'Thiruketheeswaram-MNR/86',
                    'Periyanavatkulam-MNR/87',
                    'Nagathalvu-MNR/88',
                    'Neelasenai-MNR/89',
                    'Kallikaddaskadu-MNR/90',
                    'Puthakamam-MNR/91',
                    'Uyilankulam-MNR/92',
                    'Mathoddam-MNR/93',
                    'Vannamoddai-MNR/94',
                    'Uyirtharasankulam-MNR/95',
                    'Parappankandal-MNR/96',
                ],
                key: 'Mannar Town',
            },
            {
                values: [
                    'Vellankulam-MNR/01',
                    'Thevanpiddy-MNR/02',
                    'Pali Aru-MNR/03',
                    'Illuppaikadavai-MNR/04',
                    'Anthoniyarpuram-MNR/05',
                    'Kalliyady-MNR/06',
                    'Kurai-MNR/07',
                    'Aththimoddai-MNR/08',
                    'Kovitkulam-MNR/09',
                    'Vidataltivu West-MNR/10',
                    'Vidataltivu North-MNR/11',
                    'Vidataltivu Central-MNR/12',
                    'Vidataltivu East-MNR/13',
                    'Pallamalhu-MNR/14',
                    'Kaya Nagar-MNR/15',
                    'Periyamadhu West-MNR/16',
                    'Periyamadhu East-MNR/17',
                    'Pappamoddai-MNR/18',
                    'Veddayamurippu-MNR/19',
                    'Minukkan-MNR/20',
                    'Maligaithiddal-MNR/21',
                    'Adampan-MNR/22',
                    'Palaikuly-MNR/23',
                    'Nedunkandal-MNR/24',
                    'Sornapuri-MNR/25',
                    'Karunkandal-MNR/26',
                    'Andankulam-MNR/27',
                    'Kannady-MNR/28',
                    'Alkaddively-MNR/29',
                    'Kannkandal Vannakulam-MNR/30',
                    'Kaththankalam-MNR/31',
                    'Vaddakandal-MNR/32',
                    'Palai Periymalkaddu-MNR/33',
                    'Palaiyadi Puthukulam-MNR/34',
                    'Parappukadanthan west-MNR/35',
                    'Parappukadanthan East-MNR/36',
                ],
                key: 'Manthai West',
            },
            {
                values: [
                    'Madhu-MNR/37',
                    'Periya Pandiviruchchan West-MNR/38',
                    'Periya Pandiviruchchan East-MNR/39',
                    'Palampiddy-MNR/40',
                    'Keerisuddan-MNR/41',
                    'Iranai Illuppaikulam-MNR/42',
                    'Vilaththikulam-MNR/43',
                    'Parasankalam-MNR/44',
                    'Kakkayankulam West-MNR/45',
                    'Kakkayankulam East-MNR/46',
                    'Kalmadhu-MNR/47',
                    'Maluvarayar Kaddaiyadumpan-MNR/128',
                    'Pannavedduvan-MNR/129',
                    'Pomalarnthan-MNR/130',
                    'Thekkam-MNR/131',
                    'Matha Gramam-MNR/132',
                    'Periyamurippu-MNR/133',
                ],
                key: 'Madhu',
            },
            {
                values: [
                    'Vankalai North-MNR/97',
                    'Vankalai west-MNR/98',
                    'Vankalai east-MNR/99',
                    'Thomaspuri-MNR/100',
                    'Naruvilikalam-MNR/101',
                    'Vanchchiankulam-MNR/102',
                    'Umanagari-MNR/103',
                    'Achchankulam-MNR/104',
                    'Rasamadhu-MNR/105',
                    'Moddaikadal-MNR/106',
                    'Nanaddan-MNR/107',
                    'Pallankoddai-MNR/108',
                    'Valkaipadankandal-MNR/109',
                    'Periyakadlaikadu-MNR/110',
                    'Illahadipiddy-MNR/111',
                    'Illanthaimoddai-MNR/112',
                    'Puthirakanidan-MNR/113',
                    'Razool puthuvely-MNR/114',
                    'Kanchchithalvu-MNR/115',
                    'Aththukuly-MNR/116',
                    'Chemmantivu-MNR/117',
                    'Murangan-MNR/118',
                    'Chundukuly-MNR/119',
                    'Sirukandal-MNR/120',
                    'Pontheevukandal-MNR/121',
                    'Kalimoddai Paliyankulam-MNR/122',
                    'Paritharikandal-MNR/123',
                    'Katkidanthakulam-MNR/124',
                    'Irraddaikulam-MNR/125',
                    'Cheddiamagan Kaddaiyadampan-MNR/126',
                    'Isamalaithalvu-MNR/127',
                ],
                key: 'Nanaddan',
            },
            {
                values: [
                    'Arippu west-MNR/134',
                    'Arippu east-MNR/135',
                    'Meththanvely-MNR/136',
                    'Pandaravely-MNR/137',
                    'Poonochchikulam-MNR/138',
                    'Maruthamadu-MNR/139',
                    'Veppunkulam-MNR/140',
                    'Periya Pillachchi Pokerny-MNR/141',
                    'Sinna pullachchi potkerny-MNR/142',
                    'Ahathymurippu-MNR/143',
                    'Chilavathurai-MNR/144',
                    'Saveriyarpuram-MNR/145',
                    'Puthuvely-MNR/146',
                    'Koolankulam-MNR/147',
                    'Kokkupadayan-MNR/148',
                    'Kondachchi-MNR/149',
                    'Karadikuly-MNR/150',
                    'Marichchukaddi-MNR/151',
                    'Palaikuly-MNR/152',
                    'Mallikulam-MNR/153',
                ],
                key: 'Musalai',
            },
            {
                values: [
                    'Unchalkaddy-221',
                    'Velivaithakallu-221A',
                    'Maruthodai-221B',
                    'Paddikudiyiruppu-221C',
                    'Katkulam-221D',
                    'Nedunkerny South-222',
                    'Nedunkerny North-222A',
                    'Olumadu-222B',
                    'Mamadu-222C',
                    'Kulavisuddan-222D',
                    'Maraillupai-222E',
                    'Paranthan-223',
                    'Ananthapuliyankalam-223A',
                    'Sinnaadampan-223B',
                    'Nainamadu-223C',
                    'Puliyankalam South-224',
                    'Puliyankalam North-224A',
                    'Kanagarayankulam South-225',
                    'Kanagarayankulam North-225A',
                    'Mannakulam-225B',
                ],
                key: 'Vavuniya North',
            },
            {
                values: [
                    'Periya Ulukulama-C209',
                    'Poomaduwa-C209A',
                    'Track-7-C209B',
                    'Marathanmadawa-C209C',
                    'Rangethgama-C209D',
                    'Avarantulawa-C209E',
                    'Acre 20,40, 60-C209F',
                    'Acre 400.L.B. Coloni-C209G',
                    'Madukanda-C212',
                    'Erattaperiyakulam-C212A',
                    'Alagalla-C212B',
                    'Avusadapitiya-C212C',
                    'Kalukkunamaduwa-C212D',
                    'Nedunkalama-C212E',
                    'Mahamylankulam-C212F',
                    'Mamaduwa-C213',
                    'Mahakachakodiya-C213A',
                    'Puthubulankalama-C213B',
                    'Pirappanmaduwa-C213C',
                    'Agbopura-C213D',
                ],
                key: 'Vavuniya South',
            },
            {
                values: [
                    'Vavuniya Town-214',
                    'Thandikulam-214A',
                    'Paddanichchipuliyankulam-214B',
                    'Vavuniya North-214C',
                    'Vairavapuliyan kalam-214D',
                    'Pandarikulam-214E',
                    'Thonikal-214F',
                    'Moonrumurippu-214G',
                    'Nochchimoddai-215',
                    'Maharamhaikulam-215A',
                    'Katharsinnakulam-215B',
                    'Kallikulam-215C',
                    'Puthkulam-216',
                    'Paranaddankal-216A',
                    'Rajendrankulam-217',
                    'Poovarasankulam-217A',
                    'Salampaikulam-217B',
                    'Chekkaddipulavu-217C',
                    'Velankulam-217D',
                    'Nelukulam-218',
                    'Pampaimada-218A',
                    'Kalmadu-218B',
                    'Marakkaranpalai-218C',
                    'Sasthirikoolankulam-218D',
                    'Eachchankulam-218E',
                    'Koomankulam-218F',
                    'Kanthapuram-218G',
                    'Maruthamadu-219',
                    'Palamoddai-219A',
                    'Maruthankulam-220',
                    'Semamadu-220A',
                    'Malligai-220B',
                    'Pantrikeythakulam-220C',
                    'Omanthai-220D',
                    'Arumugathanouthukulam-220E',
                    'Mahilankulam-220F',
                    'Elamaruthankulam-220G',
                    'Asikulam-244',
                    'Rambaikulam-244A',
                    'Samalankulam-244B',
                    'Kovilkulam-244C',
                    'Velikulam-244D',
                ],
                key: 'Vavuniya',
            },
            {
                values: [
                    'Andiyapuliyankulam-207',
                    'Periyathampanai-207A',
                    'Periyakadu-207B',
                    'Kannady-207C',
                    'Piramanalankulam-207D',
                    'Sooduventhapulava-208',
                    'Kurukkalputhukulam-208A',
                    'Kamhavamy Nagar-208B',
                    'Pavatkulam unit 5 & 6-208C',
                    'Pavatkulam Unit 4-208D',
                    'Pavatkulam unit 2-208E',
                    'Muthaliyakalam-210',
                    'Sinnasippikalum-210A',
                    'Neriyakulam-210B',
                    'Periyapuliyankulam-211',
                    'Cheddikulam-211A',
                    'Muhathankulam-211B',
                    'Pavatkulam Unit 9 & 10-211C',
                    'Christthokulam-211D',
                    'Kankankulam-211E',
                ],
                key: 'Vengalacheddikulam',
            },
            {
                values: [
                    'Thunukkai-MUL/16',
                    'Kalvilan-MUL/17',
                    'Uyilakulam-MUL/18',
                    'Yogapurum West-MUL/19',
                    'Mallavi-MUL/20',
                    'Yogapuram Centre-MUL/21',
                    'Thirunagar-MUL/22',
                    'Puthuvedduvan-MUL/23',
                    'Amaithipuram-MUL/24',
                    'Amplapperumalkulam-MUL/25',
                    'Alankulam-MUL/26',
                    'Therkandal-MUL/27',
                    'Yogapurum East-MUL/28',
                    'Pugalenthynagar-MUL/29',
                    'Barathinagar-MUL/30',
                    'Anichchiyankulam-MUL/31',
                    'Thenniyankulam-MUL/32',
                    'Palayamarkandy-MUL/33',
                    'Iyankulam-MUL/34',
                    'Koddaikaddiyakulam-MUL/35',
                ],
                key: 'Thunukkai',
            },
            {
                values: [
                    'Vannivilankulam-MUL/1',
                    'Ampalpuram-MUL/2',
                    'Kollavilankulam-MUL/3',
                    'Oddaruthakulam-MUL/4',
                    'Sivapuram-MUL/5',
                    'Palinagar-MUL/6',
                    'Karumpulliyan-MUL/7',
                    'Poovarasankulam-MUL/8',
                    'Pandiyankulam-MUL/9',
                    'Selvapuram-MUL/10',
                    'Moonrumurippu-MUL/11',
                    'Naddunkandal-MUL/12',
                    'Vinayagapuram-MUL/13',
                    'Ponnagar-MUL/14',
                    'Siraddikulam-MUL/15',
                ],
                key: 'Manthai East',
            },
            {
                values: [
                    'Puthukkudiyiruppu East-MUL/36',
                    'Sivanagar-MUL/37',
                    'Manthuvil-MUL/38',
                    'Malligaitheevu-MUL/39',
                    'Ananthapuram-MUL/40',
                    'Puthukkudiyiruppu West-MUL/41',
                    'Kombavil-MUL/42',
                    'Iranaipalai-MUL/43',
                    'Thevipuram-MUL/44',
                    'Vallipuram-MUL/45',
                    'Udayarkaddu North-MUL/46',
                    'Udayarkaddu South-MUL/47',
                    'Suthanthirapuram-MUL/48',
                    'Visvamadu west-MUL/49',
                    'Visvamadu East-MUL/50',
                    'Manikkaporam-MUL/51',
                    'Theravil-MUL/52',
                    'Vallavarpuram-MUL/53',
                    'Manakandal-MUL/54',
                ],
                key: 'Puthukudiyiruppu',
            },
            {
                values: [
                    'Muththayankaddu Kulam-MUL/55',
                    'Kanagaratnapuram-MUL/56',
                    'Peraru-MUL/57',
                    'Pandaravanni-MUL/58',
                    'Katchilaimadu-MUL/59',
                    'Muththuvinayagapuram-MUL/60',
                    'Oddusuddan-MUL/61',
                    'Kathaliyar Sumalankulum-MUL/62',
                    'Thaddayamalai-MUL/63',
                    'Ganeshapuram-MUL/64',
                    'Vithiyapuram-MUL/65',
                    'Karavelankandal-MUL/66',
                    'Puliyankulam-MUL/67',
                    'Koolamurippu-MUL/68',
                    'Manavalanddamppu-MUL/69',
                    'Thachchadampan-MUL/70',
                    'Olumadu-MUL/71',
                    'Ampugaman-MUL/72',
                    'Mankulam-MUL/73',
                    'Panikkankulam-MUL/74',
                    'Thirumurukandy-MUL/75',
                    'Inthupuram-MUL/76',
                    'Oththimalai-MUL/123',
                    'Periyakalam-MUL/124',
                    'Thanduwan-MUL/125',
                    'Periyalthimadu-MUL/126',
                    'Palampasi-MUL/127',
                ],
                key: 'Oddusuddan',
            },
            {
                values: [
                    'Kokulai west-MUL/77',
                    'Kokulai East-MUL/78',
                    'Karunaddukerny-MUL/79',
                    'Kokkuthoduvai south-MUL/80',
                    'Kokkuthoduvai North-MUL/81',
                    'Kokkuthoduvai Center-MUL/82',
                    'Semmalai East-MUL/83',
                    'Semmalai-MUL/84',
                    'Uppumavelli-MUL/85',
                    'Alambil North-MUL/86',
                    'Alampil south-MUL/87',
                    'Silawaththai-MUL/88',
                    'Silawaththai south-MUL/89',
                    'Kallappau south-MUL/90',
                    'Kallappau north-MUL/91',
                    'Vannankulam-MUL/92',
                    'Mullaitivu south-MUL/93',
                    'Selvapuram-MUL/94',
                    'Kovil kudiyiruppu-MUL/95',
                    'Manalkudiyiruppu-MUL/96',
                    'Mullaitivu town-MUL/97',
                    'Mullivaikkal East-MUL/98',
                    'Mullivaikkal West-MUL/99',
                    'Ambalavanan Pokkanai-MUL/100',
                    'Vattapalai-MUL/101',
                    'Keppapilava-MUL/102',
                    'Mulliyawalai west-MUL/103',
                    'Mulliyawalai North-MUL/104',
                    'Putharikadu-MUL/105',
                    'Mulliyawalai Centre-MUL/106',
                    'Mulliyavalai South-MUL/107',
                    'Mulliyavalai East-MUL/108',
                    'Thanniryoottu West-MUL109/',
                    'Mamoolai-MUL/110',
                    'Mathavalasingankulam-MUL/111',
                    'Thanniyoothu East-MUL/112',
                    'Neeravipiddi East-MUL/113',
                    'Neeravipiddi West-MUL/114',
                    'Gigrapuram-MUL/115',
                    'Kanukerny East-MUL/116',
                    'Kanukerny West-MUL/117',
                    'Kumarapuram-MUL/118',
                    'Kumulamunai west-MUL/119',
                    'Thannimurippu-MUL/120',
                    'Kumulamunai east-MUL/121',
                    'Kumulamunai Centre-MUL/122',
                ],
                key: 'Maritimepattu',
            },
            {
                values: [
                    'Nawa Gajabapura-Weli/01',
                    'Kalyanapura-Weli/02',
                    'Ehetugaswewa-Weli/03',
                    'Ethawetubewews-Weli/04',
                    'Janakapura-Weli/05',
                    'Kinbbanwewa Left-Weli/06',
                    'Nikawewa South-Weli/07',
                    'Kinbbanwewa Left-Weli/08',
                    'Gajabapura-Weli/09',
                ],
                key: 'Welioya',
            },
            {
                values: [
                    'Kovivayal-KN/78',
                    'Iyakkachchi-KN/79',
                    'Mugavil-KN/80',
                    'Masar-KN/81',
                    'Soranpattu-KN/82',
                    'Tharmakerny-KN/83',
                    'Pulopallai East-KN/84',
                    'Mullaiyady-KN/85',
                    'Thampakamam-KN/86',
                    'Pallai town-KN/87',
                    'Pulopallai west-KN/88',
                    'Allipalai-KN/89',
                    'Kachcharvely-KN/90',
                    'Arasarkerny-KN/91',
                    'Ittavil-KN/92',
                    'Mugamalai-KN/93',
                    'Vemhodukemy-KN/94',
                    'Kilaly-KN/95',
                ],
                key: 'Pachchilaipalli',
            },
            {
                values: [
                    'Kumarapuram-KN/43',
                    'Paranthan-KN/44',
                    'Umayalpuram-KN/45',
                    'Elephantpass-KN/46',
                    'Thadduvankoddy-KN/47',
                    'Korakkankadhu-KN/48',
                    'Uriyan-KN/49',
                    'Murasumoddai-KN/50',
                    'Kandawalai-KN/51',
                    'Periyakulam-KN/52',
                    'Kalmadunagar-KN/53',
                    'Tharmapuram West-KN/54',
                    'Tharmapuram East-KN/55',
                    'Puliyampokkanai-KN/56',
                    'Punnaineeravi-KN/57',
                    'Piramanthanaru-KN/58',
                ],
                key: 'Kandavalai',
            },
            {
                values: [
                    'Vannerikulam-KN/01',
                    'Anaiviluthankulam-KN/02',
                    'Kannakaipuram-KN/03',
                    'Skandapurum-KN/04',
                    'Akkarayankulam-KN/05',
                    'Konavil-KN/06',
                    'Ponnagar-KN/07',
                    'Bharathipuram-KN/08',
                    'Malayalapuram-KN/09',
                    'Vivegananthungar-KN/10',
                    'Krishnapuram-KN/11',
                    'Uthayanagar East-KN/12',
                    'Uthayanagar West-KN/13',
                    'Ambalkulam-KN/14',
                    'Selvanagar-KN/15',
                    'Ananthapuram-KN/16',
                    'Thondamannagar-KN/17',
                    'Kanagambigaikulam-KN/18',
                    'Ambalnagar-KN/19',
                    'Thiruvaiaru-KN/20',
                    'Thiruvaiaru West-KN/21',
                    'Ratnapuram-KN/22',
                    'Kilinochchi town-KN/23',
                    'Maruthanagar-KN/24',
                    'Pannankandy-KN/25',
                    'Kanagapurum-KN/26',
                    'Thirunagar South-KN/27',
                    'Thirunagar North-KN/28',
                    'Kaneshapuram-KN/29',
                    'Jeyanthinagar-KN/30',
                    'Periyaparanthan-KN/31',
                    'Uruthirapuram North-KN/32',
                    'Uruthirapuram East-KN/33',
                    'Uruthirapuram West-KN/34',
                    'Sivanagar-KN/35',
                    'Uttupalam-KN/36',
                    'Puthumurippu-KN/37',
                    'Vadakachchi-KN/38',
                    'Civic Centre-KN/39',
                    'Mayavanor-KN/40',
                    'Ramanathapuram-KN/41',
                    'Mavadiyamman-KN/42',
                ],
                key: 'Karachchi',
            },
            {
                values: [
                    'Nallur-KN/59',
                    'Alankerny-KN/60',
                    'Kollakurichchi-KN/61',
                    'Cheddiyakurichchi-KN/62',
                    'Gnanimadam-KN/63',
                    'Madduvilnadu East-KN/64',
                    'Pallikkudah-KN/65',
                    'Maddavilnadhu West-KN/66',
                    'Paramankirai-KN/67',
                    'Gowtharimunai-KN/68',
                    'Jeyapuram North-KN/69',
                    'Jeyapuram South-KN/70',
                    'Kariyalainagapaduvan-KN/71',
                    'Pallavarayankadhu-KN/72',
                    'Mulankavil-KN/73',
                    'Nachchikaduh-KN/74',
                    'Kiranchi-KN/75',
                    'Ponnaveli-KN/76',
                    'Iranaithevu-KN/77',
                ],
                key: 'Poonakary',
            },
        ],
        value_depend_field: 'divisional_secretariat',
    },
    {
        name: 'employment_primary_job',
        display_name: 'Employment Primary Job',
        field: 'employment_primary_job',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    '',
                    "Unemployed",
                    "Agriculture",
                    "Animal Husbandry",
                    "Fishing",
                    "Business - Wholesale and retail trade",
                    "Manufacturing/Handicraft",
                    "Government-Sector",
                    "Private-Sector",
                    "Non-Governmental-Sector",
                    "Skilled worker",
                    "Daily laborer",
                    "Foreign employment",
                    "Micro, Small & Medium Enterprise",
                    "Pensioner",
                    "Student",
                    "Child",
                    "Domestic Servant",
                    "Other specify"
                ],
                key: 'data',
            },
        ],
    },
    {
        name: 'are_you_a_samurdhi_beneficiary',
        display_name: 'Samurdhi Beneficiary',
        placeholder: 'Yes or No',
        field: 'are_you_a_samurdhi_beneficiary',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'sex_of_head_household',
        display_name: 'Gender of Head',
        placeholder: 'Male / Female',
        field: 'sex_of_head_household',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Male', 'Female'],
                key: 'data',
            },
        ],
    },
    {
        name: 'widowed_reason',
        display_name: 'Widowed Reason',
        placeholder: 'War, Accident etc',
        field: 'widowed_reason',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    '',
                    "Natural Death",
                    "War",
                    "Natural Disaster",
                    "Accident",
                    "Missing",
                    "Divorced",
                    "Destitude",
                    "Separate",
                    "Suicide",
                    "Fully Disabled",
                    "Prisoner",
                    "Mentally Illness",
                    "Others"
                ],
                key: 'data',
            },
        ],
    },
    {
        name: 'access_to_well',
        display_name: 'Access to Well',
        placeholder: 'Yes or No',
        field: 'access_to_well',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'sanitation_facility',
        display_name: 'Sanitation Facility',
        placeholder: 'Yes or No',
        field: 'sanitation_facility',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'electricity_in_your_house',
        display_name: 'Electricity Facility',
        placeholder: 'Yes or No',
        field: 'electricity_in_your_house',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'disabled_dependents',
        display_name: 'Disabled Dependents',
        placeholder: 'Yes or No',
        field: 'disabled_dependents',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'elder_dependents',
        display_name: 'Elder Dependents?',
        placeholder: 'Yes or No',
        field: 'elder_dependents',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'younger_fifteen_year_dependents',
        display_name: '18 old or younger dependents?',
        placeholder: 'Yes or No',
        field: 'younger_fifteen_year_dependents',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'do_you_have_own_land',
        display_name: 'Do you have own land?',
        placeholder: 'Yes or No',
        field: 'do_you_have_own_land',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'interest_self_employment',
        display_name: 'Interest in Self Employment',
        placeholder: 'Yes or No',
        field: 'interest_self_employment',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'head_household_disabled',
        display_name: 'Head of the Household Disabled',
        placeholder: 'Yes or No',
        field: 'head_household_disabled',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
    {
        name: 'primary_normal_season',
        display_name: 'Water Facility',
        field: 'primary_normal_season',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    '',
                    'Dug well',
                    'Common well-Government land',
                    'River',
                    'Main water supply of NWSDB',
                    'Village water supply',
                    'Home tube well',
                    'Common tube well ',
                    'Bowser',
                    'Bottled water',
                    'Wind power',
                    'Community water supply',
                    'Others',
                ],
                key: 'data',
            },
        ],
    },
    {
        name: 'your_house',
        display_name: 'Housing Facility',
        field: 'your_house',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    '',
                    'Permanent : House mostly in durable material (brick, asbestos, cement)',
                    'Semi permanent : House mostly in non-durable material (planks, mud, plastic sheets, cadjan)',
                    'Temporary: Tent/ plastic sheeting/ shelter in camp',
                ],
                key: 'data',
            },
        ],
    },
    {
        name: 'highest_education_head_household',
        display_name: 'Highest Education of Head',
        field: 'highest_education_head_household',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: [
                    '',
                    'Unschooling - Elders',
                    'Schooling (Grade 1-5)',
                    'Schooling (Grade 6-10)',
                    'Passed G.C.E (O/L)',
                    'Passed G.C.E (A/L)',
                    'Vocational/Technical Education',
                    'University and above',
                    'Now dropout',
                    'Preschool',
                    'Child',
                ],
                key: 'data',
            },
        ],
    },
    {
        name: 'no_of_dependent',
        display_name: 'No of Dependents',
        field: 'no_of_dependent',
        type: 'range',
        component: 'RangeComponent',
    },
    {
        name: 'age_head_household',
        display_name: 'Household Head Age',
        field: 'age_head_household',
        type: 'range',
        component: 'RangeComponent',
    },
    {
        name: 'are_you_receiving_public_monthly_assistance_currently',
        display_name: 'PMA',
        placeholder: 'Yes or No',
        field: 'are_you_receiving_public_monthly_assistance_currently',
        type: 'autocomplete',
        component: 'SelectDropdown',
        values: [
            {
                values: ['', 'Yes', 'No'],
                key: 'data',
            },
        ],
    },
//@formatter:off

  {name:'total_income_family', display_name: 'Total Income', field: 'total_income_family', type: 'range', component: 'RangeComponent',},
  {name:'value_of_the_samurdhi_stamp', display_name: 'Samurdhi Value', field: 'value_of_the_samurdhi_stamp', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_elders_assistance', display_name: 'Elders Assistance', field: 'family_public_assistance_elders_assistance', type: 'range', component: 'RangeComponent',},
  {name:'family_members_receiving_family_public_assistance_total', display_name: 'Family\'s Total Assistance', field: 'family_members_receiving_family_public_assistance_total', type: 'range', component: 'RangeComponent',},
  // {name: 'family_public_assistance_elders_assistance', display_name: 'Elders Assistance', field: 'family_public_assistance_elders_assistance', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_disability_assistance', display_name: 'Disability Assistance', field: 'family_public_assistance_disability_assistance', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_assistance_disease', display_name: 'Disease Assistance', field: 'family_public_assistance_assistance_disease', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_kidney', display_name: 'FPA Kidney', field: 'family_public_assistance_kidney', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_tb', display_name: 'FPA TB', field: 'family_public_assistance_tb', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_leprosy', display_name: 'FPA Leprosy', field: 'family_public_assistance_leprosy', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_cancer', display_name: 'FPA Cancer', field: 'family_public_assistance_cancer', type: 'range', component: 'RangeComponent',},
  {name:'family_public_assistance_spinal_code_injury', display_name: 'FPA SCI', field: 'family_public_assistance_spinal_code_injury', type: 'range', component: 'RangeComponent',},
//@formatter:on

];
