<template>
  <div class="w-full">
    <div class="flex flex-col items-center relative">
      <div class="w-full">
        <div class="my-2 flex border rounded">
          <input
            :disabled="disabled"
            @click="isOpen = !isOpen"
            @keyup="filterOptions"
            autocomplete="nofill"
            v-model="filter"
            :placeholder="placeholder"
            class="p-2 px-2 py-2 appearance-none outline-none w-full"
          />
        </div>
      </div>
      <div
        v-if="isOpen"
        v-click-outside="close"
        class="absolute shadow bg-white top-100 z-40 w-full lef-0 rounded max-h-select overflow-y-auto"
      >
        <div class="flex flex-col w-full">
          <div
            class="cursor-pointer"
            @click="selectOption(option)"
            v-for="(option, index) in filteredOptions"
            :key="index"
          >
            <div
              class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100"
            >
              <div class="w-full items-center flex">
                <div class="mx-2 -mt-1">
                  <span>{{ option.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    options: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
    value: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {},
  data() {
    return {
      isOpen: false,
      focusedOptionIndex: null,
      filteredOptions: this.options,
      filter: null,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    clearDD() {
      this.selected = null;
      this.filter = null;
      this.$emit('selected', null);
      this.close();
    },
    filterOptions($event) {
      const value = $event.target.value;
      if ($event.keyCode === 8) {
        if (this.selected) {
          this.clearDD();
          this.close();
          return;
        }
      }
      if (this.selected && !value) {
        this.clearDD();
        return null;
      }
      this.filter = value;
      this.filteredOptions = value
        ? this.options.filter(option => {
            return option.name.toLowerCase().indexOf(value) > -1;
          })
        : this.options;
    },
    selectOption(selected) {
      this.selected = selected;
      this.filter = selected.name;
      this.$emit('selected', selected);
      this.close();
    },
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    value: {
      immediate: true,
      handler(current) {
        this.filter = current ? current.name : null;
      },
    },
  },
};
</script>

<style scoped>
.top-100 {
  top: 100%;
}
.bottom-100 {
  bottom: 100%;
}
.max-h-select {
  max-height: 300px;
}
</style>
