<template>
  <date-time :timestamp="value" :format="column.format"></date-time>
</template>

<script>
import DateTime from '@/components/DateTime';

export default {
  components: {
    DateTime,
  },
  props: {
    item: Object,
    value: String,
    column: Object,
  },
};
</script>
