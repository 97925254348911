<template>
  <div class="flex mb-3 bg-surface-card-active shadow rounded-sm overflow-hidden">
    <div class="flex items-center">
      <SearchIcon class="ml-3" />
    </div>
    <input
      placeholder="Search head of household"
      class="p-3 ml-3 w-full bg-surface-card-active focus:outline-none focus:shadow-none overflow-hidden"
      @input="onSearch"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import SearchIcon from '@/components/icons/Search';

export default {
  components: {
    SearchIcon,
  },
  props: {
    prefix: String,
  },
  methods: {
    onSearch: debounce(function(event) {
      this.$emit('change', event.target.value);
    }, 300),
  },
};
</script>
